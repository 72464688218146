export const POINames = {
  'put-in': 'Put-in',
  'put-in-alt': 'Alternative put-in',
  'put-in-road': 'End of road to put-in',
  'take-out': 'Take-out',
  'take-out-alt': 'Alternative take-out',
  'take-out-road': 'End of road to take-out',
  waterfall: 'Waterfall',
  rapid: 'Rapid',
  portage: 'Portage',
  playspot: 'Playspot',
  hazard: 'Hazard',
  'river-campsite': 'River campsite',
  'wild-camping': 'Wild camping',
  'paid-camping': 'Paid camping',
  gauge: 'Gauge',
  'hike-waypoint': 'Hiking trail waypoint',
  bridge: 'Bridge',
  dam: 'dam',
  'power-plant': 'Power plant (water release)',
  'kayak-shop': 'kayak-shop',
  other: 'Other',
};

export const POITypes = Object.keys(POINames);
