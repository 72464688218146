/* eslint-disable @typescript-eslint/no-explicit-any */
import * as Types from '@whitewater-guide/schema';

import { gql } from '@apollo/client';
import {
  GaugeCoreFragmentDoc,
  GaugeLocationFragmentDoc,
  GaugeHarvestInfoFragmentDoc,
  GaugeSourceFragmentDoc,
  GaugeStatusFragmentDoc,
  GaugeLatestMeasurementFragmentDoc,
} from '@whitewater-guide/schema';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ListGaugesRowFragment = {
  __typename?: 'Gauge';
  enabled?: boolean | null | undefined;
  id: string;
  name: string;
  code: string;
  levelUnit?: string | null | undefined;
  flowUnit?: string | null | undefined;
  url?: string | null | undefined;
  timezone?: string | null | undefined;
  requestParams?: { [key: string]: any } | null | undefined;
  source: { __typename?: 'Source'; id: string };
  location?:
    | {
        __typename?: 'Point';
        id: string;
        kind: string;
        coordinates: CodegenCoordinates;
      }
    | null
    | undefined;
  status?:
    | {
        __typename?: 'HarvestStatus';
        count?: number | null | undefined;
        error?: string | null | undefined;
        lastRun?: CodegenDateTime | null | undefined;
        lastSuccess?: CodegenDateTime | null | undefined;
        nextRun?: CodegenDateTime | null | undefined;
      }
    | null
    | undefined;
  latestMeasurement?:
    | {
        __typename?: 'Measurement';
        timestamp: CodegenDateTime;
        flow?: number | null | undefined;
        level?: number | null | undefined;
      }
    | null
    | undefined;
};

export type ListGaugesQueryVariables = Types.Exact<{
  filter?: Types.InputMaybe<Types.GaugesFilter>;
  page?: Types.InputMaybe<Types.Page>;
}>;

export type ListGaugesQuery = {
  __typename?: 'Query';
  gauges: {
    __typename?: 'GaugesList';
    count: number;
    nodes: Array<{
      __typename?: 'Gauge';
      enabled?: boolean | null | undefined;
      id: string;
      name: string;
      code: string;
      levelUnit?: string | null | undefined;
      flowUnit?: string | null | undefined;
      url?: string | null | undefined;
      timezone?: string | null | undefined;
      requestParams?: { [key: string]: any } | null | undefined;
      source: { __typename?: 'Source'; id: string };
      location?:
        | {
            __typename?: 'Point';
            id: string;
            kind: string;
            coordinates: CodegenCoordinates;
          }
        | null
        | undefined;
      status?:
        | {
            __typename?: 'HarvestStatus';
            count?: number | null | undefined;
            error?: string | null | undefined;
            lastRun?: CodegenDateTime | null | undefined;
            lastSuccess?: CodegenDateTime | null | undefined;
            nextRun?: CodegenDateTime | null | undefined;
          }
        | null
        | undefined;
      latestMeasurement?:
        | {
            __typename?: 'Measurement';
            timestamp: CodegenDateTime;
            flow?: number | null | undefined;
            level?: number | null | undefined;
          }
        | null
        | undefined;
    }>;
  };
};

export const ListGaugesRowFragmentDoc = gql`
  fragment ListGaugesRow on Gauge {
    ...GaugeCore
    ...GaugeLocation
    ...GaugeHarvestInfo
    ...GaugeStatus
    ...GaugeLatestMeasurement
    source {
      id
    }
    enabled
  }
  ${GaugeCoreFragmentDoc}
  ${GaugeLocationFragmentDoc}
  ${GaugeHarvestInfoFragmentDoc}
  ${GaugeStatusFragmentDoc}
  ${GaugeLatestMeasurementFragmentDoc}
`;
export const ListGaugesDocument = gql`
  query listGauges($filter: GaugesFilter, $page: Page) {
    gauges(filter: $filter, page: $page) {
      nodes {
        ...ListGaugesRow
      }
      count
    }
  }
  ${ListGaugesRowFragmentDoc}
`;

/**
 * __useListGaugesQuery__
 *
 * To run a query within a React component, call `useListGaugesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListGaugesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListGaugesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useListGaugesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ListGaugesQuery,
    ListGaugesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ListGaugesQuery, ListGaugesQueryVariables>(
    ListGaugesDocument,
    options,
  );
}
export function useListGaugesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListGaugesQuery,
    ListGaugesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ListGaugesQuery, ListGaugesQueryVariables>(
    ListGaugesDocument,
    options,
  );
}
export type ListGaugesQueryHookResult = ReturnType<typeof useListGaugesQuery>;
export type ListGaugesLazyQueryHookResult = ReturnType<
  typeof useListGaugesLazyQuery
>;
export type ListGaugesQueryResult = Apollo.QueryResult<
  ListGaugesQuery,
  ListGaugesQueryVariables
>;
