/* eslint-disable @typescript-eslint/no-explicit-any */
import * as Types from '@whitewater-guide/schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type RemoveMediaMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;

export type RemoveMediaMutation = {
  __typename?: 'Mutation';
  removeMedia?:
    | { __typename?: 'Media'; id: string; deleted?: boolean | null | undefined }
    | null
    | undefined;
};

export const RemoveMediaDocument = gql`
  mutation removeMedia($id: ID!) {
    removeMedia(id: $id) {
      id
      deleted
    }
  }
`;
export type RemoveMediaMutationFn = Apollo.MutationFunction<
  RemoveMediaMutation,
  RemoveMediaMutationVariables
>;

/**
 * __useRemoveMediaMutation__
 *
 * To run a mutation, you first call `useRemoveMediaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveMediaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeMediaMutation, { data, loading, error }] = useRemoveMediaMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveMediaMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveMediaMutation,
    RemoveMediaMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RemoveMediaMutation, RemoveMediaMutationVariables>(
    RemoveMediaDocument,
    options,
  );
}
export type RemoveMediaMutationHookResult = ReturnType<
  typeof useRemoveMediaMutation
>;
export type RemoveMediaMutationResult =
  Apollo.MutationResult<RemoveMediaMutation>;
export type RemoveMediaMutationOptions = Apollo.BaseMutationOptions<
  RemoveMediaMutation,
  RemoveMediaMutationVariables
>;
