/* eslint-disable @typescript-eslint/no-explicit-any */
import * as Types from '@whitewater-guide/schema';

import { gql } from '@apollo/client';
import {
  GaugeCoreFragmentDoc,
  GaugeLocationFragmentDoc,
  GaugeHarvestInfoFragmentDoc,
  GaugeSourceFragmentDoc,
  GaugeStatusFragmentDoc,
  GaugeLatestMeasurementFragmentDoc,
} from '@whitewater-guide/schema';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GaugeDetailsQueryVariables = Types.Exact<{
  gaugeId?: Types.InputMaybe<Types.Scalars['ID']>;
}>;

export type GaugeDetailsQuery = {
  __typename?: 'Query';
  gauge?:
    | {
        __typename?: 'Gauge';
        id: string;
        name: string;
        code: string;
        levelUnit?: string | null | undefined;
        flowUnit?: string | null | undefined;
        url?: string | null | undefined;
        timezone?: string | null | undefined;
        requestParams?: { [key: string]: any } | null | undefined;
        location?:
          | {
              __typename?: 'Point';
              id: string;
              kind: string;
              coordinates: CodegenCoordinates;
            }
          | null
          | undefined;
        source: {
          __typename?: 'Source';
          id: string;
          name: string;
          termsOfUse?: string | null | undefined;
        };
        status?:
          | {
              __typename?: 'HarvestStatus';
              count?: number | null | undefined;
              error?: string | null | undefined;
              lastRun?: CodegenDateTime | null | undefined;
              lastSuccess?: CodegenDateTime | null | undefined;
              nextRun?: CodegenDateTime | null | undefined;
            }
          | null
          | undefined;
        latestMeasurement?:
          | {
              __typename?: 'Measurement';
              timestamp: CodegenDateTime;
              flow?: number | null | undefined;
              level?: number | null | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export const GaugeDetailsDocument = gql`
  query gaugeDetails($gaugeId: ID) {
    gauge(id: $gaugeId) {
      ...GaugeCore
      ...GaugeLocation
      ...GaugeHarvestInfo
      ...GaugeSource
      ...GaugeStatus
      ...GaugeLatestMeasurement
    }
  }
  ${GaugeCoreFragmentDoc}
  ${GaugeLocationFragmentDoc}
  ${GaugeHarvestInfoFragmentDoc}
  ${GaugeSourceFragmentDoc}
  ${GaugeStatusFragmentDoc}
  ${GaugeLatestMeasurementFragmentDoc}
`;

/**
 * __useGaugeDetailsQuery__
 *
 * To run a query within a React component, call `useGaugeDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGaugeDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGaugeDetailsQuery({
 *   variables: {
 *      gaugeId: // value for 'gaugeId'
 *   },
 * });
 */
export function useGaugeDetailsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GaugeDetailsQuery,
    GaugeDetailsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GaugeDetailsQuery, GaugeDetailsQueryVariables>(
    GaugeDetailsDocument,
    options,
  );
}
export function useGaugeDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GaugeDetailsQuery,
    GaugeDetailsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GaugeDetailsQuery, GaugeDetailsQueryVariables>(
    GaugeDetailsDocument,
    options,
  );
}
export type GaugeDetailsQueryHookResult = ReturnType<
  typeof useGaugeDetailsQuery
>;
export type GaugeDetailsLazyQueryHookResult = ReturnType<
  typeof useGaugeDetailsLazyQuery
>;
export type GaugeDetailsQueryResult = Apollo.QueryResult<
  GaugeDetailsQuery,
  GaugeDetailsQueryVariables
>;
