/* eslint-disable @typescript-eslint/no-explicit-any */
import * as Types from '@whitewater-guide/schema';

import { gql } from '@apollo/client';
import {
  RegionCoreFragmentDoc,
  RegionPoIsFragmentDoc,
  RegionFlagsFragmentDoc,
  RegionLicenseFragmentDoc,
} from '@whitewater-guide/schema';
import {
  BannerWithSourceFragmentDoc,
  BannerCoreFragmentDoc,
} from '@whitewater-guide/schema';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type RegionDetailsInternalFragment = {
  __typename?: 'Region';
  description?: string | null | undefined;
  bounds: Array<CodegenCoordinates>;
  sku?: string | null | undefined;
  id: string;
  name: string;
  season?: string | null | undefined;
  seasonNumeric: Array<number>;
  hidden?: boolean | null | undefined;
  premium?: boolean | null | undefined;
  editable: boolean;
  hasPremiumAccess: boolean;
  copyright?: string | null | undefined;
  room?: { __typename?: 'Room'; id: string; alias: string } | null | undefined;
  pois: Array<{
    __typename?: 'Point';
    id: string;
    kind: string;
    name?: string | null | undefined;
    description?: string | null | undefined;
    coordinates: CodegenCoordinates;
  }>;
  license?:
    | {
        __typename?: 'License';
        slug?: string | null | undefined;
        name: string;
        url?: string | null | undefined;
      }
    | null
    | undefined;
};

export type RegionDetailsFragment = {
  __typename?: 'Region';
  description?: string | null | undefined;
  bounds: Array<CodegenCoordinates>;
  sku?: string | null | undefined;
  id: string;
  name: string;
  season?: string | null | undefined;
  seasonNumeric: Array<number>;
  hidden?: boolean | null | undefined;
  premium?: boolean | null | undefined;
  editable: boolean;
  hasPremiumAccess: boolean;
  copyright?: string | null | undefined;
  banners: {
    __typename?: 'RegionBannerConnection';
    nodes: Array<{
      __typename?: 'Banner';
      id: string;
      name: string;
      slug: string;
      priority: number;
      enabled: boolean;
      placement: Types.BannerPlacement;
      link?: string | null | undefined;
      extras?: { [key: string]: any } | null | undefined;
      source: {
        __typename?: 'BannerSource';
        kind: Types.BannerKind;
        url: string;
      };
    }>;
  };
  room?: { __typename?: 'Room'; id: string; alias: string } | null | undefined;
  pois: Array<{
    __typename?: 'Point';
    id: string;
    kind: string;
    name?: string | null | undefined;
    description?: string | null | undefined;
    coordinates: CodegenCoordinates;
  }>;
  license?:
    | {
        __typename?: 'License';
        slug?: string | null | undefined;
        name: string;
        url?: string | null | undefined;
      }
    | null
    | undefined;
};

export type RegionDetailsQueryVariables = Types.Exact<{
  regionId?: Types.InputMaybe<Types.Scalars['ID']>;
  bannerWidth?: Types.InputMaybe<Types.Scalars['Int']>;
}>;

export type RegionDetailsQuery = {
  __typename?: 'Query';
  region?:
    | {
        __typename?: 'Region';
        description?: string | null | undefined;
        bounds: Array<CodegenCoordinates>;
        sku?: string | null | undefined;
        id: string;
        name: string;
        season?: string | null | undefined;
        seasonNumeric: Array<number>;
        hidden?: boolean | null | undefined;
        premium?: boolean | null | undefined;
        editable: boolean;
        hasPremiumAccess: boolean;
        copyright?: string | null | undefined;
        banners: {
          __typename?: 'RegionBannerConnection';
          nodes: Array<{
            __typename?: 'Banner';
            id: string;
            name: string;
            slug: string;
            priority: number;
            enabled: boolean;
            placement: Types.BannerPlacement;
            link?: string | null | undefined;
            extras?: { [key: string]: any } | null | undefined;
            source: {
              __typename?: 'BannerSource';
              kind: Types.BannerKind;
              url: string;
            };
          }>;
        };
        room?:
          | { __typename?: 'Room'; id: string; alias: string }
          | null
          | undefined;
        pois: Array<{
          __typename?: 'Point';
          id: string;
          kind: string;
          name?: string | null | undefined;
          description?: string | null | undefined;
          coordinates: CodegenCoordinates;
        }>;
        license?:
          | {
              __typename?: 'License';
              slug?: string | null | undefined;
              name: string;
              url?: string | null | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export const RegionDetailsInternalFragmentDoc = gql`
  fragment RegionDetailsInternal on Region {
    ...RegionCore
    ...RegionPOIs
    ...RegionFlags
    ...RegionLicense
    room {
      id
      alias
    }
    description
    bounds
    sku
  }
  ${RegionCoreFragmentDoc}
  ${RegionPoIsFragmentDoc}
  ${RegionFlagsFragmentDoc}
  ${RegionLicenseFragmentDoc}
`;
export const RegionDetailsFragmentDoc = gql`
  fragment RegionDetails on Region {
    ...RegionDetailsInternal
    banners {
      nodes {
        ...BannerWithSource
      }
    }
  }
  ${RegionDetailsInternalFragmentDoc}
  ${BannerWithSourceFragmentDoc}
`;
export const RegionDetailsDocument = gql`
  query regionDetails($regionId: ID, $bannerWidth: Int) {
    region(id: $regionId) {
      ...RegionDetailsInternal
      banners {
        nodes {
          ...BannerCore
          source {
            kind
            url(width: $bannerWidth)
          }
        }
      }
    }
  }
  ${RegionDetailsInternalFragmentDoc}
  ${BannerCoreFragmentDoc}
`;

/**
 * __useRegionDetailsQuery__
 *
 * To run a query within a React component, call `useRegionDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRegionDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRegionDetailsQuery({
 *   variables: {
 *      regionId: // value for 'regionId'
 *      bannerWidth: // value for 'bannerWidth'
 *   },
 * });
 */
export function useRegionDetailsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    RegionDetailsQuery,
    RegionDetailsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<RegionDetailsQuery, RegionDetailsQueryVariables>(
    RegionDetailsDocument,
    options,
  );
}
export function useRegionDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RegionDetailsQuery,
    RegionDetailsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<RegionDetailsQuery, RegionDetailsQueryVariables>(
    RegionDetailsDocument,
    options,
  );
}
export type RegionDetailsQueryHookResult = ReturnType<
  typeof useRegionDetailsQuery
>;
export type RegionDetailsLazyQueryHookResult = ReturnType<
  typeof useRegionDetailsLazyQuery
>;
export type RegionDetailsQueryResult = Apollo.QueryResult<
  RegionDetailsQuery,
  RegionDetailsQueryVariables
>;
