/* eslint-disable @typescript-eslint/no-explicit-any */
import * as Types from '@whitewater-guide/schema';

import { gql } from '@apollo/client';
import { SectionNameShortFragmentDoc } from '@whitewater-guide/schema';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type FindSectionsQueryVariables = Types.Exact<{
  filter?: Types.InputMaybe<Types.SectionsFilter>;
  page?: Types.InputMaybe<Types.Page>;
}>;

export type FindSectionsQuery = {
  __typename?: 'Query';
  sections: {
    __typename?: 'SectionsList';
    nodes: Array<{
      __typename?: 'Section';
      id: string;
      name: string;
      river: { __typename?: 'River'; id: string; name: string };
    }>;
  };
};

export const FindSectionsDocument = gql`
  query findSections($filter: SectionsFilter, $page: Page) {
    sections(filter: $filter, page: $page) {
      nodes {
        ...SectionNameShort
      }
    }
  }
  ${SectionNameShortFragmentDoc}
`;

/**
 * __useFindSectionsQuery__
 *
 * To run a query within a React component, call `useFindSectionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindSectionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindSectionsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useFindSectionsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    FindSectionsQuery,
    FindSectionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FindSectionsQuery, FindSectionsQueryVariables>(
    FindSectionsDocument,
    options,
  );
}
export function useFindSectionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FindSectionsQuery,
    FindSectionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FindSectionsQuery, FindSectionsQueryVariables>(
    FindSectionsDocument,
    options,
  );
}
export type FindSectionsQueryHookResult = ReturnType<
  typeof useFindSectionsQuery
>;
export type FindSectionsLazyQueryHookResult = ReturnType<
  typeof useFindSectionsLazyQuery
>;
export type FindSectionsQueryResult = Apollo.QueryResult<
  FindSectionsQuery,
  FindSectionsQueryVariables
>;
