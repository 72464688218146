/* eslint-disable @typescript-eslint/no-explicit-any */
import * as Types from '@whitewater-guide/schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SuggestionsCountQueryVariables = Types.Exact<{
  [key: string]: never;
}>;

export type SuggestionsCountQuery = {
  __typename?: 'Query';
  suggestions?:
    | { __typename?: 'SuggestionsList'; count: number }
    | null
    | undefined;
  sections: { __typename?: 'SectionsList'; count: number };
};

export const SuggestionsCountDocument = gql`
  query suggestionsCount {
    suggestions(filter: { status: [pending] }) {
      count
    }
    sections(filter: { verified: false, editable: true }) {
      count
    }
  }
`;

/**
 * __useSuggestionsCountQuery__
 *
 * To run a query within a React component, call `useSuggestionsCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useSuggestionsCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSuggestionsCountQuery({
 *   variables: {
 *   },
 * });
 */
export function useSuggestionsCountQuery(
  baseOptions?: Apollo.QueryHookOptions<
    SuggestionsCountQuery,
    SuggestionsCountQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SuggestionsCountQuery, SuggestionsCountQueryVariables>(
    SuggestionsCountDocument,
    options,
  );
}
export function useSuggestionsCountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SuggestionsCountQuery,
    SuggestionsCountQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SuggestionsCountQuery,
    SuggestionsCountQueryVariables
  >(SuggestionsCountDocument, options);
}
export type SuggestionsCountQueryHookResult = ReturnType<
  typeof useSuggestionsCountQuery
>;
export type SuggestionsCountLazyQueryHookResult = ReturnType<
  typeof useSuggestionsCountLazyQuery
>;
export type SuggestionsCountQueryResult = Apollo.QueryResult<
  SuggestionsCountQuery,
  SuggestionsCountQueryVariables
>;
