/* eslint-disable @typescript-eslint/no-explicit-any */
import * as Types from './types';

import gql from 'graphql-tag';
export type BannerCoreFragment = {
  __typename?: 'Banner';
  id: string;
  name: string;
  slug: string;
  priority: number;
  enabled: boolean;
  placement: Types.BannerPlacement;
  link?: string | null | undefined;
  extras?: { [key: string]: any } | null | undefined;
};

export type BannerWithSourceFragment = {
  __typename?: 'Banner';
  id: string;
  name: string;
  slug: string;
  priority: number;
  enabled: boolean;
  placement: Types.BannerPlacement;
  link?: string | null | undefined;
  extras?: { [key: string]: any } | null | undefined;
  source: { __typename?: 'BannerSource'; kind: Types.BannerKind; url: string };
};

export type BannerRegionsFragment = {
  __typename?: 'Banner';
  regions?:
    | {
        __typename?: 'BannerRegionConnection';
        count: number;
        nodes: Array<{ __typename?: 'Region'; id: string; name: string }>;
      }
    | null
    | undefined;
};

export type BannerGroupsFragment = {
  __typename?: 'Banner';
  groups?:
    | {
        __typename?: 'BannerGroupConnection';
        count: number;
        nodes: Array<{ __typename?: 'Group'; id: string; name: string }>;
      }
    | null
    | undefined;
};

export type TimestampedMeta_Descent_Fragment = {
  __typename?: 'Descent';
  createdAt: CodegenDateTime;
  updatedAt: CodegenDateTime;
};

export type TimestampedMeta_Gauge_Fragment = {
  __typename?: 'Gauge';
  createdAt: CodegenDateTime;
  updatedAt: CodegenDateTime;
};

export type TimestampedMeta_Media_Fragment = {
  __typename?: 'Media';
  createdAt: CodegenDateTime;
  updatedAt: CodegenDateTime;
};

export type TimestampedMeta_Region_Fragment = {
  __typename?: 'Region';
  createdAt: CodegenDateTime;
  updatedAt: CodegenDateTime;
};

export type TimestampedMeta_River_Fragment = {
  __typename?: 'River';
  createdAt: CodegenDateTime;
  updatedAt: CodegenDateTime;
};

export type TimestampedMeta_Section_Fragment = {
  __typename?: 'Section';
  createdAt: CodegenDateTime;
  updatedAt: CodegenDateTime;
};

export type TimestampedMeta_Source_Fragment = {
  __typename?: 'Source';
  createdAt: CodegenDateTime;
  updatedAt: CodegenDateTime;
};

export type TimestampedMeta_User_Fragment = {
  __typename?: 'User';
  createdAt: CodegenDateTime;
  updatedAt: CodegenDateTime;
};

export type TimestampedMetaFragment =
  | TimestampedMeta_Descent_Fragment
  | TimestampedMeta_Gauge_Fragment
  | TimestampedMeta_Media_Fragment
  | TimestampedMeta_Region_Fragment
  | TimestampedMeta_River_Fragment
  | TimestampedMeta_Section_Fragment
  | TimestampedMeta_Source_Fragment
  | TimestampedMeta_User_Fragment;

export type DescentCoreFragment = {
  __typename?: 'Descent';
  id: string;
  startedAt: CodegenDateTime;
  duration?: number | null | undefined;
  comment?: string | null | undefined;
  public?: boolean | null | undefined;
  level?:
    | {
        __typename?: 'DescentLevel';
        unit?: string | null | undefined;
        value?: number | null | undefined;
      }
    | null
    | undefined;
};

export type GaugeCoreFragment = {
  __typename?: 'Gauge';
  id: string;
  name: string;
  code: string;
  levelUnit?: string | null | undefined;
  flowUnit?: string | null | undefined;
  url?: string | null | undefined;
  timezone?: string | null | undefined;
};

export type GaugeLocationFragment = {
  __typename?: 'Gauge';
  location?:
    | {
        __typename?: 'Point';
        id: string;
        kind: string;
        coordinates: CodegenCoordinates;
      }
    | null
    | undefined;
};

export type GaugeHarvestInfoFragment = {
  __typename?: 'Gauge';
  requestParams?: { [key: string]: any } | null | undefined;
};

export type GaugeLatestMeasurementFragment = {
  __typename?: 'Gauge';
  latestMeasurement?:
    | {
        __typename?: 'Measurement';
        timestamp: CodegenDateTime;
        flow?: number | null | undefined;
        level?: number | null | undefined;
      }
    | null
    | undefined;
};

export type GaugeStatusFragment = {
  __typename?: 'Gauge';
  status?:
    | {
        __typename?: 'HarvestStatus';
        count?: number | null | undefined;
        error?: string | null | undefined;
        lastRun?: CodegenDateTime | null | undefined;
        lastSuccess?: CodegenDateTime | null | undefined;
        nextRun?: CodegenDateTime | null | undefined;
      }
    | null
    | undefined;
};

export type GaugeSourceFragment = {
  __typename?: 'Gauge';
  source: {
    __typename?: 'Source';
    id: string;
    name: string;
    termsOfUse?: string | null | undefined;
  };
};

export type GaugeBindingAllFragment = {
  __typename?: 'GaugeBinding';
  minimum?: number | null | undefined;
  maximum?: number | null | undefined;
  optimum?: number | null | undefined;
  impossible?: number | null | undefined;
  approximate?: boolean | null | undefined;
  formula?: string | null | undefined;
};

export type GaugeForChartFragment = {
  __typename?: 'Gauge';
  id: string;
  name: string;
  code: string;
  levelUnit?: string | null | undefined;
  flowUnit?: string | null | undefined;
  url?: string | null | undefined;
  timezone?: string | null | undefined;
  source: {
    __typename?: 'Source';
    id: string;
    name: string;
    termsOfUse?: string | null | undefined;
  };
  latestMeasurement?:
    | {
        __typename?: 'Measurement';
        timestamp: CodegenDateTime;
        flow?: number | null | undefined;
        level?: number | null | undefined;
      }
    | null
    | undefined;
};

export type GroupCoreFragment = {
  __typename?: 'Group';
  id: string;
  name: string;
  sku?: string | null | undefined;
};

export type MediaCoreFragment = {
  __typename?: 'Media';
  id: string;
  kind: Types.MediaKind;
  copyright?: string | null | undefined;
  description?: string | null | undefined;
  url: string;
  resolution?: Array<number> | null | undefined;
  weight?: number | null | undefined;
  license?:
    | {
        __typename?: 'License';
        name: string;
        slug?: string | null | undefined;
        url?: string | null | undefined;
      }
    | null
    | undefined;
};

export type MediaWithThumbFragment = {
  __typename?: 'Media';
  image?: string | null | undefined;
  id: string;
  kind: Types.MediaKind;
  copyright?: string | null | undefined;
  description?: string | null | undefined;
  url: string;
  resolution?: Array<number> | null | undefined;
  weight?: number | null | undefined;
  thumb?: string | null | undefined;
  license?:
    | {
        __typename?: 'License';
        name: string;
        slug?: string | null | undefined;
        url?: string | null | undefined;
      }
    | null
    | undefined;
};

export type PointCoreFragment = {
  __typename?: 'Point';
  id: string;
  kind: string;
  name?: string | null | undefined;
  description?: string | null | undefined;
  coordinates: CodegenCoordinates;
};

export type RegionCoreFragment = {
  __typename?: 'Region';
  id: string;
  name: string;
  season?: string | null | undefined;
  seasonNumeric: Array<number>;
};

export type RegionPoIsFragment = {
  __typename?: 'Region';
  pois: Array<{
    __typename?: 'Point';
    id: string;
    kind: string;
    name?: string | null | undefined;
    description?: string | null | undefined;
    coordinates: CodegenCoordinates;
  }>;
};

export type RegionFlagsFragment = {
  __typename?: 'Region';
  hidden?: boolean | null | undefined;
  premium?: boolean | null | undefined;
  editable: boolean;
  hasPremiumAccess: boolean;
};

export type RegionLicenseFragment = {
  __typename?: 'Region';
  copyright?: string | null | undefined;
  license?:
    | {
        __typename?: 'License';
        slug?: string | null | undefined;
        name: string;
        url?: string | null | undefined;
      }
    | null
    | undefined;
};

export type RegionAdminFragment = {
  __typename?: 'Region';
  id: string;
  hidden?: boolean | null | undefined;
  premium?: boolean | null | undefined;
  sku?: string | null | undefined;
  mapsSize?: number | null | undefined;
  coverImage: {
    __typename?: 'RegionCoverImage';
    mobile?: string | null | undefined;
  };
};

export type SectionNameShortFragment = {
  __typename?: 'Section';
  id: string;
  name: string;
  river: { __typename?: 'River'; id: string; name: string };
};

export type SectionNameFragment = {
  __typename?: 'Section';
  id: string;
  name: string;
  altNames: Array<string>;
  river: {
    __typename?: 'River';
    id: string;
    name: string;
    altNames: Array<string>;
  };
};

export type SectionCoreFragment = {
  __typename?: 'Section';
  hidden: boolean;
  helpNeeded?: string | null | undefined;
  demo?: boolean | null | undefined;
  verified?: boolean | null | undefined;
  favorite?: boolean | null | undefined;
  season?: string | null | undefined;
  seasonNumeric: Array<number>;
  distance?: number | null | undefined;
  drop?: number | null | undefined;
  duration?: number | null | undefined;
  difficulty: number;
  difficultyXtra?: string | null | undefined;
  rating?: number | null | undefined;
  timezone?: string | null | undefined;
  id: string;
  name: string;
  altNames: Array<string>;
  river: {
    __typename?: 'River';
    id: string;
    name: string;
    altNames: Array<string>;
  };
};

export type SectionEndsFragment = {
  __typename?: 'Section';
  putIn: {
    __typename?: 'Point';
    id: string;
    coordinates: CodegenCoordinates;
    kind: string;
  };
  takeOut: {
    __typename?: 'Point';
    id: string;
    coordinates: CodegenCoordinates;
    kind: string;
  };
};

export type SectionFlowsFragment = {
  __typename?: 'Section';
  flowsText?: string | null | undefined;
  timezone?: string | null | undefined;
  levels?:
    | {
        __typename?: 'GaugeBinding';
        minimum?: number | null | undefined;
        maximum?: number | null | undefined;
        optimum?: number | null | undefined;
        impossible?: number | null | undefined;
        approximate?: boolean | null | undefined;
        formula?: string | null | undefined;
      }
    | null
    | undefined;
  flows?:
    | {
        __typename?: 'GaugeBinding';
        minimum?: number | null | undefined;
        maximum?: number | null | undefined;
        optimum?: number | null | undefined;
        impossible?: number | null | undefined;
        approximate?: boolean | null | undefined;
        formula?: string | null | undefined;
      }
    | null
    | undefined;
};

export type SectionMeasurementsFragment = {
  __typename?: 'Section';
  flowsText?: string | null | undefined;
  timezone?: string | null | undefined;
  gauge?:
    | {
        __typename?: 'Gauge';
        id: string;
        name: string;
        code: string;
        levelUnit?: string | null | undefined;
        flowUnit?: string | null | undefined;
        url?: string | null | undefined;
        timezone?: string | null | undefined;
        source: {
          __typename?: 'Source';
          id: string;
          name: string;
          termsOfUse?: string | null | undefined;
        };
        location?:
          | {
              __typename?: 'Point';
              id: string;
              kind: string;
              coordinates: CodegenCoordinates;
            }
          | null
          | undefined;
        latestMeasurement?:
          | {
              __typename?: 'Measurement';
              timestamp: CodegenDateTime;
              flow?: number | null | undefined;
              level?: number | null | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
  levels?:
    | {
        __typename?: 'GaugeBinding';
        minimum?: number | null | undefined;
        maximum?: number | null | undefined;
        optimum?: number | null | undefined;
        impossible?: number | null | undefined;
        approximate?: boolean | null | undefined;
        formula?: string | null | undefined;
      }
    | null
    | undefined;
  flows?:
    | {
        __typename?: 'GaugeBinding';
        minimum?: number | null | undefined;
        maximum?: number | null | undefined;
        optimum?: number | null | undefined;
        impossible?: number | null | undefined;
        approximate?: boolean | null | undefined;
        formula?: string | null | undefined;
      }
    | null
    | undefined;
};

export type SectionPoIsFragment = {
  __typename?: 'Section';
  pois: Array<{
    __typename?: 'Point';
    id: string;
    name?: string | null | undefined;
    description?: string | null | undefined;
    coordinates: CodegenCoordinates;
    kind: string;
  }>;
};

export type SectionTagsFragment = {
  __typename?: 'Section';
  tags: Array<{
    __typename?: 'Tag';
    id: string;
    name: string;
    category: Types.TagCategory;
  }>;
};

export type SectionLicenseFragment = {
  __typename?: 'Section';
  copyright?: string | null | undefined;
  license?:
    | {
        __typename?: 'License';
        slug?: string | null | undefined;
        name: string;
        url?: string | null | undefined;
      }
    | null
    | undefined;
};

export type DescentSectionFragment = {
  __typename?: 'Section';
  difficulty: number;
  difficultyXtra?: string | null | undefined;
  timezone?: string | null | undefined;
  id: string;
  name: string;
  region: { __typename?: 'Region'; id: string; name: string };
  levels?:
    | {
        __typename?: 'GaugeBinding';
        minimum?: number | null | undefined;
        maximum?: number | null | undefined;
        optimum?: number | null | undefined;
        impossible?: number | null | undefined;
        approximate?: boolean | null | undefined;
        formula?: string | null | undefined;
      }
    | null
    | undefined;
  flows?:
    | {
        __typename?: 'GaugeBinding';
        minimum?: number | null | undefined;
        maximum?: number | null | undefined;
        optimum?: number | null | undefined;
        impossible?: number | null | undefined;
        approximate?: boolean | null | undefined;
        formula?: string | null | undefined;
      }
    | null
    | undefined;
  gauge?:
    | {
        __typename?: 'Gauge';
        id: string;
        name: string;
        code: string;
        levelUnit?: string | null | undefined;
        flowUnit?: string | null | undefined;
        url?: string | null | undefined;
        timezone?: string | null | undefined;
        source: {
          __typename?: 'Source';
          id: string;
          name: string;
          termsOfUse?: string | null | undefined;
        };
        latestMeasurement?:
          | {
              __typename?: 'Measurement';
              timestamp: CodegenDateTime;
              flow?: number | null | undefined;
              level?: number | null | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
  river: { __typename?: 'River'; id: string; name: string };
  putIn: {
    __typename?: 'Point';
    id: string;
    coordinates: CodegenCoordinates;
    kind: string;
  };
  takeOut: {
    __typename?: 'Point';
    id: string;
    coordinates: CodegenCoordinates;
    kind: string;
  };
};

export type SourceCoreFragment = {
  __typename?: 'Source';
  id: string;
  name: string;
  termsOfUse?: string | null | undefined;
  script: string;
  cron?: string | null | undefined;
  url?: string | null | undefined;
};

export type SuggestionCoreFragment = {
  __typename?: 'Suggestion';
  id: string;
  description?: string | null | undefined;
  copyright?: string | null | undefined;
  resolution?: Array<number> | null | undefined;
  image?: string | null | undefined;
  section: {
    __typename?: 'Section';
    id: string;
    name: string;
    river: { __typename?: 'River'; id: string; name: string };
    region: { __typename?: 'Region'; id: string; name: string };
  };
};

export type SuggestionMetaFragment = {
  __typename?: 'Suggestion';
  createdAt: CodegenDateTime;
  status: Types.SuggestionStatus;
  resolvedAt?: CodegenDateTime | null | undefined;
  createdBy?:
    | { __typename?: 'User'; id: string; name: string }
    | null
    | undefined;
  resolvedBy?:
    | { __typename?: 'User'; id: string; name: string }
    | null
    | undefined;
};

export type UserCoreFragment = {
  __typename?: 'User';
  id: string;
  name: string;
  avatar?: string | null | undefined;
  email?: string | null | undefined;
};

export type MyProfileFragment = {
  __typename?: 'User';
  editor: boolean;
  admin: boolean;
  language: string;
  imperial: boolean;
  verified: boolean;
  id: string;
  name: string;
  avatar?: string | null | undefined;
  email?: string | null | undefined;
  editorSettings?:
    | { __typename?: 'EditorSettings'; language?: string | null | undefined }
    | null
    | undefined;
  accounts: Array<{
    __typename?: 'SocialMediaAccount';
    id: string;
    provider: string;
  }>;
};

export const BannerCoreFragmentDoc = gql`
  fragment BannerCore on Banner {
    id
    name
    slug
    priority
    enabled
    placement
    link
    extras
  }
`;
export const BannerWithSourceFragmentDoc = gql`
  fragment BannerWithSource on Banner {
    ...BannerCore
    source {
      kind
      url
    }
  }
  ${BannerCoreFragmentDoc}
`;
export const BannerRegionsFragmentDoc = gql`
  fragment BannerRegions on Banner {
    regions {
      nodes {
        id
        name
      }
      count
    }
  }
`;
export const BannerGroupsFragmentDoc = gql`
  fragment BannerGroups on Banner {
    groups {
      nodes {
        id
        name
      }
      count
    }
  }
`;
export const TimestampedMetaFragmentDoc = gql`
  fragment TimestampedMeta on Timestamped {
    createdAt
    updatedAt
  }
`;
export const DescentCoreFragmentDoc = gql`
  fragment DescentCore on Descent {
    id
    startedAt
    duration
    level {
      unit
      value
    }
    comment
    public
  }
`;
export const GaugeHarvestInfoFragmentDoc = gql`
  fragment GaugeHarvestInfo on Gauge {
    requestParams
  }
`;
export const GaugeStatusFragmentDoc = gql`
  fragment GaugeStatus on Gauge {
    status {
      count
      error
      lastRun
      lastSuccess
      nextRun
    }
  }
`;
export const GroupCoreFragmentDoc = gql`
  fragment GroupCore on Group {
    id
    name
    sku
  }
`;
export const MediaCoreFragmentDoc = gql`
  fragment MediaCore on Media {
    id
    kind
    copyright
    description
    license {
      name
      slug
      url
    }
    url
    resolution
    weight
  }
`;
export const MediaWithThumbFragmentDoc = gql`
  fragment MediaWithThumb on Media {
    ...MediaCore
    image
    thumb: image
  }
  ${MediaCoreFragmentDoc}
`;
export const RegionCoreFragmentDoc = gql`
  fragment RegionCore on Region {
    id
    name
    season
    seasonNumeric
  }
`;
export const PointCoreFragmentDoc = gql`
  fragment PointCore on Point {
    id
    kind
    name
    description
    coordinates
  }
`;
export const RegionPoIsFragmentDoc = gql`
  fragment RegionPOIs on Region {
    pois {
      ...PointCore
    }
  }
  ${PointCoreFragmentDoc}
`;
export const RegionFlagsFragmentDoc = gql`
  fragment RegionFlags on Region {
    hidden
    premium
    editable
    hasPremiumAccess
  }
`;
export const RegionLicenseFragmentDoc = gql`
  fragment RegionLicense on Region {
    copyright
    license {
      slug
      name
      url
    }
  }
`;
export const RegionAdminFragmentDoc = gql`
  fragment RegionAdmin on Region {
    id
    hidden
    premium
    sku
    mapsSize
    coverImage {
      mobile
    }
  }
`;
export const SectionNameFragmentDoc = gql`
  fragment SectionName on Section {
    id
    name
    altNames
    river {
      id
      name
      altNames
    }
  }
`;
export const SectionCoreFragmentDoc = gql`
  fragment SectionCore on Section {
    ...SectionName
    hidden
    helpNeeded
    demo
    verified
    favorite
    season
    seasonNumeric
    distance
    drop
    duration
    difficulty
    difficultyXtra
    rating
    timezone
  }
  ${SectionNameFragmentDoc}
`;
export const GaugeCoreFragmentDoc = gql`
  fragment GaugeCore on Gauge {
    id
    name
    code
    levelUnit
    flowUnit
    url
    timezone
  }
`;
export const GaugeLocationFragmentDoc = gql`
  fragment GaugeLocation on Gauge {
    location {
      id
      kind
      coordinates
    }
  }
`;
export const GaugeLatestMeasurementFragmentDoc = gql`
  fragment GaugeLatestMeasurement on Gauge {
    latestMeasurement {
      timestamp
      flow
      level
    }
  }
`;
export const GaugeBindingAllFragmentDoc = gql`
  fragment GaugeBindingAll on GaugeBinding {
    minimum
    maximum
    optimum
    impossible
    approximate
    formula
  }
`;
export const SectionFlowsFragmentDoc = gql`
  fragment SectionFlows on Section {
    levels {
      ...GaugeBindingAll
    }
    flows {
      ...GaugeBindingAll
    }
    flowsText
    timezone
  }
  ${GaugeBindingAllFragmentDoc}
`;
export const SectionMeasurementsFragmentDoc = gql`
  fragment SectionMeasurements on Section {
    gauge {
      ...GaugeCore
      ...GaugeLocation
      ...GaugeLatestMeasurement
      source {
        id
        name
        termsOfUse
      }
    }
    ...SectionFlows
  }
  ${GaugeCoreFragmentDoc}
  ${GaugeLocationFragmentDoc}
  ${GaugeLatestMeasurementFragmentDoc}
  ${SectionFlowsFragmentDoc}
`;
export const SectionPoIsFragmentDoc = gql`
  fragment SectionPOIs on Section {
    pois {
      id
      name
      description
      coordinates
      kind
    }
  }
`;
export const SectionTagsFragmentDoc = gql`
  fragment SectionTags on Section {
    tags {
      id
      name
      category
    }
  }
`;
export const SectionLicenseFragmentDoc = gql`
  fragment SectionLicense on Section {
    copyright
    license {
      slug
      name
      url
    }
  }
`;
export const SectionNameShortFragmentDoc = gql`
  fragment SectionNameShort on Section {
    id
    name
    river {
      id
      name
    }
  }
`;
export const GaugeSourceFragmentDoc = gql`
  fragment GaugeSource on Gauge {
    source {
      id
      name
      termsOfUse
    }
  }
`;
export const GaugeForChartFragmentDoc = gql`
  fragment GaugeForChart on Gauge {
    ...GaugeCore
    ...GaugeSource
    ...GaugeLatestMeasurement
  }
  ${GaugeCoreFragmentDoc}
  ${GaugeSourceFragmentDoc}
  ${GaugeLatestMeasurementFragmentDoc}
`;
export const SectionEndsFragmentDoc = gql`
  fragment SectionEnds on Section {
    putIn {
      id
      coordinates
      kind
    }
    takeOut {
      id
      coordinates
      kind
    }
  }
`;
export const DescentSectionFragmentDoc = gql`
  fragment DescentSection on Section {
    ...SectionNameShort
    difficulty
    difficultyXtra
    timezone
    region {
      id
      name
    }
    levels {
      ...GaugeBindingAll
    }
    flows {
      ...GaugeBindingAll
    }
    gauge {
      ...GaugeForChart
    }
    ...SectionEnds
  }
  ${SectionNameShortFragmentDoc}
  ${GaugeBindingAllFragmentDoc}
  ${GaugeForChartFragmentDoc}
  ${SectionEndsFragmentDoc}
`;
export const SourceCoreFragmentDoc = gql`
  fragment SourceCore on Source {
    id
    name
    termsOfUse
    script
    cron
    url
  }
`;
export const SuggestionCoreFragmentDoc = gql`
  fragment SuggestionCore on Suggestion {
    id
    description
    copyright
    resolution
    image
    section {
      id
      name
      river {
        id
        name
      }
      region {
        id
        name
      }
    }
  }
`;
export const SuggestionMetaFragmentDoc = gql`
  fragment SuggestionMeta on Suggestion {
    createdAt
    createdBy {
      id
      name
    }
    status
    resolvedBy {
      id
      name
    }
    resolvedAt
  }
`;
export const UserCoreFragmentDoc = gql`
  fragment UserCore on User {
    id
    name
    avatar
    email
  }
`;
export const MyProfileFragmentDoc = gql`
  fragment MyProfile on User {
    ...UserCore
    editor
    admin
    language
    imperial
    verified
    editorSettings {
      language
    }
    accounts {
      id
      provider
    }
  }
  ${UserCoreFragmentDoc}
`;
