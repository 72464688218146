/* eslint-disable @typescript-eslint/no-explicit-any */
import * as Types from '@whitewater-guide/schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ListedRiverFragment = {
  __typename?: 'River';
  id: string;
  name: string;
  altNames: Array<string>;
  region: { __typename?: 'Region'; id: string; name: string };
  sections: { __typename?: 'RiverSectionConnection'; count: number };
};

export type ListRiversQueryVariables = Types.Exact<{
  page?: Types.InputMaybe<Types.Page>;
  filter?: Types.InputMaybe<Types.RiversFilter>;
}>;

export type ListRiversQuery = {
  __typename?: 'Query';
  rivers: {
    __typename?: 'RiversList';
    count: number;
    nodes: Array<{
      __typename?: 'River';
      id: string;
      name: string;
      altNames: Array<string>;
      region: { __typename?: 'Region'; id: string; name: string };
      sections: { __typename?: 'RiverSectionConnection'; count: number };
    }>;
  };
};

export const ListedRiverFragmentDoc = gql`
  fragment ListedRiver on River {
    id
    name
    altNames
    region {
      id
      name
    }
    sections {
      count
    }
  }
`;
export const ListRiversDocument = gql`
  query listRivers($page: Page, $filter: RiversFilter) {
    rivers(page: $page, filter: $filter) {
      nodes {
        ...ListedRiver
      }
      count
    }
  }
  ${ListedRiverFragmentDoc}
`;

/**
 * __useListRiversQuery__
 *
 * To run a query within a React component, call `useListRiversQuery` and pass it any options that fit your needs.
 * When your component renders, `useListRiversQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListRiversQuery({
 *   variables: {
 *      page: // value for 'page'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useListRiversQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ListRiversQuery,
    ListRiversQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ListRiversQuery, ListRiversQueryVariables>(
    ListRiversDocument,
    options,
  );
}
export function useListRiversLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListRiversQuery,
    ListRiversQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ListRiversQuery, ListRiversQueryVariables>(
    ListRiversDocument,
    options,
  );
}
export type ListRiversQueryHookResult = ReturnType<typeof useListRiversQuery>;
export type ListRiversLazyQueryHookResult = ReturnType<
  typeof useListRiversLazyQuery
>;
export type ListRiversQueryResult = Apollo.QueryResult<
  ListRiversQuery,
  ListRiversQueryVariables
>;
