/* eslint-disable @typescript-eslint/no-explicit-any */
import * as Types from '@whitewater-guide/schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SectionNameQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;

export type SectionNameQuery = {
  __typename?: 'Query';
  section?:
    | {
        __typename?: 'Section';
        id: string;
        name: string;
        river: { __typename?: 'River'; id: string; name: string };
      }
    | null
    | undefined;
};

export const SectionNameDocument = gql`
  query sectionName($id: ID!) {
    section(id: $id) {
      id
      name
      river {
        id
        name
      }
    }
  }
`;

/**
 * __useSectionNameQuery__
 *
 * To run a query within a React component, call `useSectionNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useSectionNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSectionNameQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSectionNameQuery(
  baseOptions: Apollo.QueryHookOptions<
    SectionNameQuery,
    SectionNameQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SectionNameQuery, SectionNameQueryVariables>(
    SectionNameDocument,
    options,
  );
}
export function useSectionNameLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SectionNameQuery,
    SectionNameQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SectionNameQuery, SectionNameQueryVariables>(
    SectionNameDocument,
    options,
  );
}
export type SectionNameQueryHookResult = ReturnType<typeof useSectionNameQuery>;
export type SectionNameLazyQueryHookResult = ReturnType<
  typeof useSectionNameLazyQuery
>;
export type SectionNameQueryResult = Apollo.QueryResult<
  SectionNameQuery,
  SectionNameQueryVariables
>;
