/* eslint-disable @typescript-eslint/no-explicit-any */
import * as Types from '@whitewater-guide/schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateEditorSettingsMutationVariables = Types.Exact<{
  settings: Types.EditorSettingsInput;
}>;

export type UpdateEditorSettingsMutation = {
  __typename?: 'Mutation';
  updateEditorSettings?:
    | {
        __typename?: 'User';
        id: string;
        editorSettings?:
          | {
              __typename?: 'EditorSettings';
              language?: string | null | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export const UpdateEditorSettingsDocument = gql`
  mutation updateEditorSettings($settings: EditorSettingsInput!) {
    updateEditorSettings(editorSettings: $settings) {
      id
      editorSettings {
        language
      }
    }
  }
`;
export type UpdateEditorSettingsMutationFn = Apollo.MutationFunction<
  UpdateEditorSettingsMutation,
  UpdateEditorSettingsMutationVariables
>;

/**
 * __useUpdateEditorSettingsMutation__
 *
 * To run a mutation, you first call `useUpdateEditorSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEditorSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEditorSettingsMutation, { data, loading, error }] = useUpdateEditorSettingsMutation({
 *   variables: {
 *      settings: // value for 'settings'
 *   },
 * });
 */
export function useUpdateEditorSettingsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateEditorSettingsMutation,
    UpdateEditorSettingsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateEditorSettingsMutation,
    UpdateEditorSettingsMutationVariables
  >(UpdateEditorSettingsDocument, options);
}
export type UpdateEditorSettingsMutationHookResult = ReturnType<
  typeof useUpdateEditorSettingsMutation
>;
export type UpdateEditorSettingsMutationResult =
  Apollo.MutationResult<UpdateEditorSettingsMutation>;
export type UpdateEditorSettingsMutationOptions = Apollo.BaseMutationOptions<
  UpdateEditorSettingsMutation,
  UpdateEditorSettingsMutationVariables
>;
