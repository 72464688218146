/* eslint-disable @typescript-eslint/no-explicit-any */
import gql from 'graphql-tag';

export const typeDefs = gql`
  schema {
    query: Query
    mutation: Mutation
  }

  directive @admin on FIELD_DEFINITION

  type Banner implements NamedNode {
    "Client-side flag for lists, not persisted"
    deleted: Boolean
    "Whether the banner should be displayed or not"
    enabled: Boolean!
    "Arbitrary data for extra schema flexibility"
    extras: JSON
    "Which region groups"
    groups: BannerGroupConnection
    "Banner's uuid"
    id: ID!
    "link for image banners, null for WebView banners"
    link: String
    "For admin convenience"
    name: String!
    "Banner place in the app"
    placement: BannerPlacement!
    "Priority to choose banner, the higher the better"
    priority: Int!
    "Which regions"
    regions: BannerRegionConnection
    "Unique one-word alias"
    slug: String!
    "Source to display banner from"
    source: BannerSource!
  }

  type BannerGroupConnection {
    count: Int!
    nodes: [Group!]!
  }

  input BannerInput {
    "Whether the banner should be displayed or not"
    enabled: Boolean!
    "Arbitrary data for extra schema flexibility"
    extras: JSON
    "Which region groups"
    groups: [RefInput!]!
    "exisiting banner id or null for new banners"
    id: ID
    "link for image banners, null for WebView banners"
    link: String
    "For admin convenience"
    name: String!
    "Banner place in the app"
    placement: BannerPlacement!
    "Priority to choose banner, the higher the better"
    priority: Int!
    "Which regions"
    regions: [RefInput!]!
    "Unique alias, cannot be edited"
    slug: String!
    "Source to display banner from"
    source: BannerSourceInput!
  }

  enum BannerKind {
    "Static png/jpg image"
    Image
    "WebView (mobile) or iFrame (web)"
    WebView
  }

  "Places in the app where we can display banner"
  enum BannerPlacement {
    mobileRegionDescription
    mobileSectionDescription
    mobileSectionMedia
    mobileSectionRow
  }

  type BannerRegionConnection {
    count: Int!
    nodes: [Region!]!
  }

  type BannerSource {
    "kind of banner"
    kind: BannerKind!
    "ratio is inferred from placement"
    ratio: Float @deprecated
    """
    Deprecated, use URL istead
    For WebView - URL
    For Image - full url
    """
    src(width: Int): String! @deprecated
    """
    For WebView - URL
    For Image - full url
    """
    url(width: Int): String!
  }

  input BannerSourceInput {
    kind: BannerKind!
    url: String!
  }

  type BannersList {
    count: Int!
    nodes: [Banner!]!
  }

  type BoomPromoInfo {
    "Promocode pre-generated and sent to backers"
    code: String!
    "regions group name which this promo code grants or null if this is one-region promo code"
    groupName: String
    "Null for one-region promo codes, group sku otherwise"
    groupSku: String
    "Unique id, same as code"
    id: ID!
    "true if promocode has been already used"
    redeemed: Boolean
  }

  type BulkInsertResult {
    count: Int!
    log: String!
  }

  """
  When used as input, this is either [lon, lat, alt] tuple where alt can be null, or [lon, lat] tuple
  When used as return value, this is [lon, lat, alt] tuple, where alt defaults to 0
  """
  scalar Coordinates

  scalar Cursor

  scalar Date

  scalar DateTime

  type Descent implements Node & Timestamped {
    "User's comment"
    comment: String
    "Creation date"
    createdAt: DateTime!
    "Duration in milliseconds"
    duration: Int
    "UUID of descent"
    id: ID!
    "Water level at the moment of descent"
    level: DescentLevel
    "True if this descent should be visible to everyone"
    public: Boolean
    "Section on which was descended"
    section: Section!
    "Date and time of start of descent"
    startedAt: DateTime!
    "Date of last update"
    updatedAt: DateTime!
    "User who made this decent"
    user: User!
  }

  type DescentEdge {
    cursor: Cursor!
    node: Descent!
  }

  input DescentInput {
    "User's comment"
    comment: String
    "Duration in milliseconds"
    duration: Int
    "UUID of existing descent or null for new desceent"
    id: ID
    "Water level at the moment of descent"
    level: DescentLevelInput
    "True if this descent should be visible to everyone"
    public: Boolean
    "ID of section which was decended"
    sectionId: ID!
    "Date and time of start of descent"
    startedAt: DateTime!
  }

  type DescentLevel {
    unit: String
    value: Float
  }

  input DescentLevelInput {
    unit: String
    value: Float!
  }

  type DescentsConnection {
    edges: [DescentEdge!]!
    pageInfo: PageInfo!
  }

  input DescentsFilter {
    "Range of difficulty of descended section"
    difficulty: [Float!]
    "Max start date of descent"
    endDate: DateTime
    "ID of descended section"
    sectionId: String
    "Search string for descended section name"
    sectionName: String
    "Min start date of descent"
    startDate: DateTime
    "ID of user who recorded this descent"
    userId: String
  }

  type EditorSettings {
    "current editor UI language"
    language: String
  }

  input EditorSettingsInput {
    language: String
  }

  type Gauge implements NamedNode & Timestamped {
    "Unique code from upsteam, or synthetic code if not provided"
    code: String!
    "Creation date (in whitewater.guide)"
    createdAt: DateTime!
    "Is gauge being harvested?"
    enabled: Boolean
    "Flow unit (e.g. m3/s, cfs), or null if gauge doesn't measure flows"
    flowUnit: String
    "Unique ID"
    id: ID!
    "Deprecated, renamed to latestMeasurement"
    lastMeasurement: Measurement @deprecated
    "Last known measurement"
    latestMeasurement: Measurement
    "Level unit (e.g. m, inches), or null if gauge doesn't measure levels"
    levelUnit: String
    "Gauge location"
    location: Point
    "Human-friendly name"
    name: String!
    "Arbitrary upstream request params"
    requestParams: JSON @admin
    "Gauge's data source"
    source: Source!
    "Last known harvest result"
    status: HarvestStatus
    "IANA timezone name, one of listed here https://github.com/evansiroky/timezone-boundary-builder"
    timezone: String
    "Updated date (in whitewater.guide)"
    updatedAt: DateTime!
    "Gauge web page (for humans)"
    url: String
  }

  type GaugeBinding {
    "If true, then the gauge gives very vague idea of actual section flows (e.g. the gauge is on other tributary)"
    approximate: Boolean
    "Optional formula to compute section flow from gauge value, e.g '(x + 10) * 2' where x is gauge value"
    formula: String
    "It is insanely dangerous or physically impossible to run river this high"
    impossible: Float
    "The river is still runnable above this level, but difficulty is considerably higher than mentioned here"
    maximum: Float
    "Below this level the river is not enjoyable to paddle"
    minimum: Float
    "The best level for paddlers who consider themselves to be in the same class as the section"
    optimum: Float
  }

  input GaugeBindingInput {
    "If true, then the gauge gives very vague idea of actual section flows (e.g. the gauge is on other tributary)"
    approximate: Boolean
    "Optional formula to compute section flow from gauge value, e.g '(x + 10) * 2' where x is gauge value"
    formula: String
    "It is insanely dangerous or physically impossible to run river this high"
    impossible: Float
    "The river is still runnable above this level, but difficulty is considerably higher than mentioned here"
    maximum: Float
    "Below this level the river is not enjoyable to paddle"
    minimum: Float
    "The best level for paddlers who consider themselves to be in the same class as the section"
    optimum: Float
  }

  input GaugeInput {
    "Unique code from upsteam, or synthetic code if not provided"
    code: String!
    "Flow unit (e.g. m3/s, cfs), or null if gauge doesn't measure flows"
    flowUnit: String
    "UUID of exisitng gauge or null to create new gauge"
    id: ID
    "Level unit (e.g. m, inches), or null if gauge doesn't measure levels"
    levelUnit: String
    "Gauge location"
    location: PointInput
    "Gauge name"
    name: String!
    "Arbitrary upstream request params"
    requestParams: JSON
    "Gauge's source"
    source: RefInput!
    "IANA timezone name, one of listed here https://github.com/evansiroky/timezone-boundary-builder"
    timezone: String
    "Gauge web page (for humans)"
    url: String
  }

  input GaugesFilter {
    "Gauge's region ID"
    regionId: ID
    "Search string for gauge name"
    search: String
    "Gauge's source ID"
    sourceId: ID
  }

  type GaugesList {
    count: Int!
    nodes: [Gauge!]!
  }

  """
  Group of regions, used to provide access to several premium regions with one promocode
  """
  type Group implements NamedNode {
    "unique string, can be used as slug"
    id: ID!
    "Human-friendly group name"
    name: String!
    "Regions"
    regions: GroupRegionConnection!
    "SKU - identifier in iOS/Android store"
    sku: String
  }

  input GroupInput {
    "unique string, can be used as slug"
    id: String
    "Human-friendly group name"
    name: String!
    "SKU - identifier in iOS/Android store"
    sku: String
  }

  type GroupRegionConnection {
    count: Int!
    nodes: [Region!]!
  }

  type GroupsList {
    count: Int!
    nodes: [Group!]!
  }

  "Meta information about gauge processing by whitewater.guide"
  type HarvestStatus {
    "How many measurements were harvested last time"
    count: Int
    "Error message from last harvest"
    error: String
    "When was this gauge/source last harvested"
    lastRun: DateTime
    "When was this gauge/source last harvested and returned one or more measurements"
    lastSuccess: DateTime
    """
    When will this gauge/source be harvested next time
    Deprecated, renamed to nextRun
    """
    next: DateTime @deprecated
    "When will this gauge/source be harvested next time"
    nextRun: DateTime
    """
    Did last harvest result in error or not
    Deprecated, because now it's just error field as boolean
    """
    success: Boolean @deprecated
    """
    When was this gauge/source last harvested
    Depreatced, renamed to lastRun
    """
    timestamp: DateTime @deprecated
  }

  scalar JSON

  "This type represents information about licensing of piece of content (region/section/media)"
  type License {
    """
    Full license name
    e.g. 'Attribution-ShareAlike 4.0 International (CC BY-SA 4.0)'
    """
    name: String!
    """
    License code which can be used to search it and to get standard logo
    e.g. 'CC_BY-SA'
    """
    slug: String
    """
    License text url
    e.g. https://creativecommons.org/licenses/by-sa/4.0/
    """
    url: String
  }

  "This type represents information about licensing of piece of content (region/section/media)"
  input LicenseInput {
    """
    Full license name
    e.g. 'Attribution-ShareAlike 4.0 International (CC BY-SA 4.0)'
    """
    name: String!
    """
    License code which can be used to search it and to get standard logo
    e.g. 'CC_BY-SA'
    """
    slug: String
    """
    License text url
    e.g. https://creativecommons.org/licenses/by-sa/4.0/
    """
    url: String
  }

  "Measurement represents gauge's value at one point in time"
  type Measurement {
    "Flow value (cms/cfs etc.) if present"
    flow: Float
    "Level value (cm/inches etc.) if present"
    level: Float
    "When was this measurement taken (UTC)"
    timestamp: DateTime!
  }

  input MeasurementsFilter {
    "Start of period in UTC, see gorge for default value"
    from: DateTime
    "End of period in UTC, defaults to now"
    to: DateTime
  }

  type Media implements Timestamped {
    "copyright"
    copyright: String
    createdAt: DateTime!
    "Not persistent. Indicates that client should not display this item in lists"
    deleted: Boolean
    "Human-friendly description"
    description: String
    "Unique ID"
    id: ID!
    "Absolute image url (or thumb, when parameters are set)"
    image(height: Int, width: Int): String
    "Photo, video or blog"
    kind: MediaKind!
    "Licensing information"
    license: License
    "Required for images - [width, height] array. Makes no sense for blogs and videos (yet)"
    resolution: [Int!]
    "Image size in bytes. Null for blogs and videos"
    size: Int
    updatedAt: DateTime!
    """
    Full URL for videos and blogs,
    For images, use image instead
    """
    url: String!
    "weight for sorting"
    weight: Int
  }

  input MediaInput {
    "Copyright"
    copyright: String
    "Human-friendly description"
    description: String
    "UUID of existing media or null for new media"
    id: ID
    "Photo, video or blog"
    kind: MediaKind!
    "Licensing information"
    license: LicenseInput
    "Required for images - [width, height] array. Makes no sense for blogs and videos (yet)"
    resolution: [Int!]
    "Full URL for videos and blogs, filename for uploaded images"
    url: String!
    "Weight for sorting"
    weight: Int
  }

  enum MediaKind {
    blog
    photo
    video
  }

  type MediaList {
    count: Int!
    nodes: [Media!]!
  }

  type Mutation {
    "Adds user to the list of region editors"
    addEditor(regionId: ID!, userId: ID!): Boolean @admin
    "Adds FCM token for push notifications"
    addFCMToken(token: String!): Boolean
    "deprecated, used by old mobile client."
    addPurchase(purchase: PurchaseInput!): Boolean @deprecated
    "Adds region to group"
    addRegionToGroup(groupId: ID!, regionId: ID!): Boolean @admin
    "Creates suggestion"
    addSuggestion(suggestion: SuggestionInput!): Suggestion
    "Changes admin-level settings of region"
    administrateRegion(settings: RegionAdminSettings!): Region @admin
    "Changes admin-only properties of a section"
    administrateSection(id: ID!, settings: SectionAdminSettings!): Section
      @admin
    "Automatically creates or updates gauges in this source"
    autofillSource(id: ID!): [Gauge] @admin
    """
    This mutation will fetch tar.gz archive and insert sections in it
    Each JSON file in archive must be array of Partial<SectionInput> objects
    TODO: also upload photos from archive
    """
    bulkInsert(
      archiveURL: String!
      hidden: Boolean
      regionId: ID!
    ): BulkInsertResult @admin
    "Changes region of a river"
    changeRiverRegion(regionId: ID!, riverId: ID!): River
    """
    Called by user who was registered via social networks.
    Given token sent to email, will set email+password to user
    Similar to resetRequest/reset flow for users who forgot their passwords
    """
    connectEmail(email: String!, password: String!, token: String!): User
    "Deletes descent by id"
    deleteDescent(id: ID!): Boolean
    "Deletes users's profile"
    deleteProfile: Boolean
    "Deletes profile of other user by id or email, admin only"
    deleteUser(email: String, id: String): Boolean @admin
    "Subscribes to mailchimp mailing list"
    mailSubscribe(mail: String!): Boolean
    """
    Merges source section into destination section.
    Source section will be deleted, its logbooks, media and POIs will be reattached to destination section instead
    """
    mergeSections(destinationId: ID!, sourceId: ID!): Boolean
    "deletes banner"
    removeBanner(id: ID!): Banner @admin
    "Removes user from the list of region editors"
    removeEditor(regionId: ID!, userId: ID!): Boolean @admin
    "Removes FCM token for push notifications"
    removeFCMToken(token: String!): Boolean
    "Deletes gauge"
    removeGauge(id: ID!): ID @admin
    "Deletes all gauges in one source"
    removeGauges(sourceId: ID!): [ID] @admin
    "Deletes group"
    removeGroup(id: String!): String @admin
    "Deletes media item"
    removeMedia(id: ID!): Media
    "Deletes region"
    removeRegion(id: ID!): ID @admin
    "Removes region from group"
    removeRegionFromGroup(groupId: ID!, regionId: ID!): Boolean @admin
    "Deletes river (all the sections must be deleted beforehand)"
    removeRiver(id: ID!): ID
    "Deletes section"
    removeSection(id: ID!): ID
    "Deletes source"
    removeSource(id: ID!): ID @admin
    "Deletes tag"
    removeTag(id: String!): String @admin
    """
    Called by user who was registered via social networks before to obtain email+password login
    Will send an email with token
    Similar to resetRequest/reset flow for users who forgot their passwords
    """
    requestConnectEmail(email: String!): Boolean
    "Marks suggestion as resolved or rejected"
    resolveSuggestion(id: ID!, status: SuggestionStatus!): Suggestion
    "Saves purchase. Section ID can be used to return premium section instantly"
    savePurchase(purchase: PurchaseInput!, sectionId: ID): SavedPurchase
    "Toggle favorite status of region, returns region with new status"
    toggleFavoriteRegion(favorite: Boolean!, id: ID!): Region!
    "Toggle favorite status of section, returns section with new status"
    toggleFavoriteSection(favorite: Boolean!, id: ID!): Section!
    "Enables or disables source"
    toggleSource(enabled: Boolean!, id: ID!): Source @admin
    "Modifies editor settings"
    updateEditorSettings(editorSettings: EditorSettingsInput!): User
    "Updates current user's profile"
    updateProfile(user: UserInput!): User
    "creates or updates banner"
    upsertBanner(banner: BannerInput!): Banner @admin
    """
    Creates or updates descent
    Shared token can be used to indicated that several descents
    belonging to different users are the same physical descent.
    """
    upsertDescent(descent: DescentInput!, shareToken: String): Descent
    "Creates or updates gauge"
    upsertGauge(gauge: GaugeInput!): Gauge @admin
    "Creates or updates group"
    upsertGroup(group: GroupInput!): Group @admin
    "Creates or updates region"
    upsertRegion(region: RegionInput!): Region
    "Creates or updates river"
    upsertRiver(river: RiverInput!): River
    "Creates or updates section"
    upsertSection(section: SectionInput!): Section
    "Creates or updates media item belonging to a section"
    upsertSectionMedia(media: MediaInput!, sectionId: ID!): Media
    "Creates or updates source"
    upsertSource(source: SourceInput!): Source @admin
    "Creates or updates tag"
    upsertTag(tag: TagInput!): Tag @admin
  }

  interface NamedNode {
    id: ID!
    name: String!
  }

  interface Node {
    id: ID!
  }

  input Page {
    "Cursor pagination is currently used in descents"
    after: Cursor
    limit: Int
    offset: Int
  }

  type PageInfo {
    endCursor: Cursor
    hasMore: Boolean!
  }

  type Point {
    "[longitude, latitude, altitude] tuple"
    coordinates: Coordinates!
    "Human-friendly description"
    description: String
    "Unique ID"
    id: ID!
    "Dictionary value, point type"
    kind: String!
    "Human-friendly name"
    name: String
    "This POI is premium"
    premium: Boolean!
  }

  input PointInput {
    "[longitude, latitude, altitude] tuple, where altitude can be null"
    coordinates: Coordinates!
    "Human-friendly description"
    description: String
    "Unique ID"
    id: ID
    "Dictionary value, point type"
    kind: String
    "Human-friendly name"
    name: String
  }

  enum PostPolicyVersion {
    V3
  }

  input PurchaseInput {
    "Arbitary extra data which we want to save just in case"
    extra: JSON
    "ios, android or boomstarter"
    platform: String!
    "Group sku or region sku"
    productId: String!
    "Receipt from platform"
    receipt: String
    "Date of purchase"
    transactionDate: DateTime
    "This is promo code for boomstarter"
    transactionId: String!
  }

  type Query {
    "get banner by id, returns null without id"
    banner(id: ID): Banner @admin
    "lists existing banners"
    banners: BannersList @admin
    "Gets information about promocode. Is it group promocode or one-region promocode"
    checkBoomPromo(code: String!): BoomPromoInfo
    """
    Get descent by id or share token
    Share token allows to get private descent of other user
    If none are provided, returns null
    """
    descent(id: ID, shareToken: String): Descent
    """
    Generates share token that can be used by others to copy private descents
    """
    descentShareToken(id: ID!): String
    "Lists descents"
    descents(filter: DescentsFilter, page: Page): DescentsConnection
    "Lists favorite regions of current user"
    favoriteRegions: RegionsList!
    "Lists favorite sections of current user, optionally filtering by region"
    favoriteSections(regionId: ID): SectionsList!
    "Lists users"
    findUsers(filter: UserFilterOptions!): [User!]! @admin
    "Gets gauge by ID. Returns null if no ID was provided"
    gauge(id: ID): Gauge
    "Lists gauges"
    gauges(filter: GaugesFilter, page: Page): GaugesList!
    "Lists all groups, optionally only ones that include provided region"
    groups(regionId: ID): GroupsList @admin
    "Deprecated, renamed to simply measurements"
    lastMeasurements(days: Int!, gaugeId: ID, sectionId: ID): [Measurement!]!
      @deprecated
    "Gets current user's profile"
    me: User
    "Retrieves measurements by section or gauge id"
    measurements(
      "deprecated, use filter instead"
      days: Int
      filter: MeasurementsFilter
      gaugeId: ID
      sectionId: ID
    ): [Measurement!]!
    "Gets media item by id. If no id is given, returns null"
    media(id: ID): Media
    "Gets all media by section id"
    mediaBySection(page: Page, sectionId: ID!): MediaList!
    "Lists descents of current user"
    myDescents(filter: DescentsFilter, page: Page): DescentsConnection
    "Gets regions that current user can activate with one-region promocode"
    promoRegions: [Region!]!
    "Gets region by ID. Returns null when ID is not provided"
    region(id: ID): Region
    "Lists region editors"
    regionEditors(regionId: ID!): [User!] @admin
    "Lists regions"
    regions(filter: RegionFilterOptions, page: Page): RegionsList!
    "Gets river by ID, returns null if ID was not provided"
    river(id: ID): River
    "Lists rivers"
    rivers(filter: RiversFilter, page: Page): RiversList!
    "Lists available gorge scripts (data sources)"
    scripts: [Script!]! @admin
    "Gets section by id. Returns null when id is not provided"
    section(id: ID): Section
    "List sections"
    sections(
      filter: SectionsFilter
      page: Page
      updatedAfter: DateTime
    ): SectionsList!
    "Returns edit logs"
    sectionsEditLog(
      filter: SectionsEditLogFilter
      page: Page
    ): SectionsEditLogList! @admin
    "Gets source by ID. Returns null when id is not provided"
    source(id: ID): Source
    "Lists data sources"
    sources(page: Page): SourcesList!
    "Lists suggestions"
    suggestions(filter: SuggestionsFilter, page: Page): SuggestionsList
    "Lists tag"
    tags: [Tag!]!
    "Generates link to upload image to object storage (S3/Minio)"
    uploadLink(
      "Post policy version is required to generate links that older clients can use"
      version: PostPolicyVersion
    ): UploadLink
  }

  input RefInput {
    id: ID!
    name: String
  }

  type Region implements NamedNode & Timestamped {
    """
    Banners
    """
    banners(page: Page): RegionBannerConnection!
    "Approximate region bounds, array of [lon, lat, alt] tuples"
    bounds: [Coordinates!]!
    "copyright"
    copyright: String
    "Cover image"
    coverImage: RegionCoverImage!
    "Date when the region was added to whitewater.guide"
    createdAt: DateTime!
    "Human-friendly markdown description"
    description: String
    "Indicates that current user can edit this region and everything inside it"
    editable: Boolean!
    "Indicates that current user has marked this region as favorite"
    favorite: Boolean
    """
    List of gauges.
    Important: only gauges linked to sections are listed.
    """
    gauges(page: Page): RegionGaugeConnection!
    "Indicates that current user can see premium content in this region"
    hasPremiumAccess: Boolean!
    "Indicates that only admins can see this region"
    hidden: Boolean @admin
    "Unique UUID"
    id: ID!
    "Licensing information"
    license: License
    "This is for admin to edit manually, users should get this via mediaSummary field"
    mapsSize: Int @admin
    "Summary of all media belonging to this region"
    mediaSummary: RegionMediaSummary!
    "Human-friendly name"
    name: String!
    "Points of interest"
    pois: [Point!]!
    "True if region has some premium content"
    premium: Boolean
    "List of rivers"
    rivers(page: Page): RegionRiverConnection!
    """
    Chat room for this region
    This resolver will lazily create room if it doesn't exist. So it's not recommended to call it in list queries
    """
    room: Room
    "Human-friendly description to complement numeric months list"
    season: String
    "Array of numers between 0 and 23, corresponding to half-months"
    seasonNumeric: [Int!]!
    """
    List of sections
    """
    sections(
      filter: SectionsFilter
      page: Page
      updatedAfter: DateTime
    ): RegionSectionConnection!
    "SKU - identifier in iOS/Android store"
    sku: String
    """
    List of sources
    """
    sources(page: Page): RegionSourceConnection!
    "Date when the region was last modified"
    updatedAt: DateTime!
  }

  """
  Region settings available only to admins
  """
  input RegionAdminSettings {
    "Cover images for various platforms"
    coverImage: RegionCoverImageInput!
    "Indicates that only admins can see this region"
    hidden: Boolean!
    "Region UUID"
    id: ID!
    "Map size in bytes"
    mapsSize: Int!
    "True if region has some premium content"
    premium: Boolean!
    "SKU - identifier in iOS/Android store"
    sku: String
  }

  type RegionBannerConnection {
    count: Int!
    nodes: [Banner!]!
  }

  type RegionCoverImage {
    mobile(width: Int): String
  }

  input RegionCoverImageInput {
    mobile: String
  }

  input RegionFilterOptions {
    searchString: String
  }

  type RegionGaugeConnection {
    count: Int!
    nodes: [Gauge!]!
  }

  input RegionInput {
    "Approximate region bounds, array of [lon, lat, alt] tuples"
    bounds: [Coordinates!]!
    "copyright"
    copyright: String
    "Human-friendly markdown description"
    description: String
    "UUID of existing region or null to create new region"
    id: ID
    "Licensing information"
    license: LicenseInput
    "Human-friendly name"
    name: String!
    "Points of interest"
    pois: [PointInput!]
    "Human-friendly description to complement numeric months list"
    season: String
    "Array of numers between 0 and 23, corresponding to half-months"
    seasonNumeric: [Int!]
  }

  type RegionMediaSummary {
    blog: RegionMediaSummaryItem!
    maps: RegionMediaSummaryItem!
    photo: RegionMediaSummaryItem!
    video: RegionMediaSummaryItem!
  }

  type RegionMediaSummaryItem {
    "Total number of media items of certain kind"
    count: Int!
    "Total stored size of media items of certain kind"
    size: Int!
  }

  type RegionRiverConnection {
    count: Int!
    nodes: [River!]!
  }

  type RegionSectionConnection {
    count: Int!
    nodes: [Section!]!
  }

  type RegionSourceConnection {
    count: Int!
    nodes: [Source!]!
  }

  type RegionsList {
    count: Int!
    nodes: [Region!]!
  }

  type River implements NamedNode & Timestamped {
    "Alternative names, e.g. english transliteration for names that contain umlauts"
    altNames: [String!]!
    "Creation date"
    createdAt: DateTime!
    "Unique river ID"
    id: ID!
    "Human-friendly river name"
    name: String!
    "River region"
    region: Region!
    "List of sections"
    sections(page: Page): RiverSectionConnection!
    "Last modification date"
    updatedAt: DateTime!
  }

  input RiverInput {
    "Alternative names, e.g. english transliteration for names that contain umlauts"
    altNames: [String!]
    "UUID of existing river or null to create new river"
    id: ID
    "Optional field for imported data"
    importId: String
    "Human-friendly river name"
    name: String!
    "River region"
    region: RefInput!
  }

  type RiverSectionConnection {
    count: Int!
    nodes: [Section!]!
  }

  input RiversFilter {
    "Optional region ID"
    regionId: ID
    "String to search in river names"
    search: String
  }

  type RiversList {
    count: Int!
    nodes: [River!]!
  }

  """
  Chat room
  """
  type Room {
    """
    Canonical room alias (starts with #uuid and has domain)
    """
    alias: String!
    """
    Chat room id (starts with exclamation mark and has domain)
    """
    id: ID!
  }

  type SavedPurchase {
    regions: [Region!]!
    section: Section
  }

  "Script represents script (data source) in gorge"
  type Script {
    id: String!
    name: String!
  }

  type Section implements NamedNode & Timestamped {
    "Alternative names, e.g. english transliteration for names that contain umlauts"
    altNames: [String!]!
    "copyright"
    copyright: String
    "When was this section added to whitewater.guide"
    createdAt: DateTime!
    "Original author, can be null when user was deleted or for very old sections"
    createdBy: User
    "Is demo section in premium region"
    demo: Boolean
    """
    Guidebook page in markdown. Subject to premium access limitations
    Returns null if requires premium access
    Returns empty string if no description provided
    """
    description: String
    "Floating point difficulty value, i.e. 4.5 means IV+"
    difficulty: Float!
    "Short extra difficulty to be written in brackets, i.e. what's inside brackets for 'IV (2xV, X)' difficuly"
    difficultyXtra: String
    "Approximate section length, in km"
    distance: Float
    "Approximate difference between put-in and take-out altitudes, in m"
    drop: Float
    "Dictionay value, approximate time required to run this section"
    duration: Int
    "Indicates that current user has marked this section as favorite"
    favorite: Boolean
    "Flow gauge bindings"
    flows: GaugeBinding
    "Human-friendly description to complement gauge bindings, e.g. 'After heavy rainfall'"
    flowsText: String
    "Section gauge"
    gauge: Gauge
    "What should be changed about this section?"
    helpNeeded: String
    "Section is not ready for publication yet"
    hidden: Boolean!
    "Unique section ID"
    id: ID!
    "Level gauge bindings"
    levels: GaugeBinding
    "Licensing information"
    license: License
    "Photos, videos and blogs"
    media(page: Page): SectionMediaConnection!
    "Human-friendly section name (without river name, e.g. just 'Upper')"
    name: String!
    "Points of interest"
    pois: [Point!]!
    "Put-in location"
    putIn: Point!
    "Subjective rating from 0 to 5 stars, half stars allowed"
    rating: Float
    "Section region"
    region: Region!
    "Section river"
    river: River!
    """
    Chat room for this region
    This resolver will lazily create room if it doesn't exist. So it's not recommended to call it in list queries
    """
    room: Room
    "Human-friendly description to complement numeric months list"
    season: String
    "Array of numers between 0 and 23, corresponding to half-months"
    seasonNumeric: [Int!]!
    "Array of [lon, lat, alt] points to roughly match river shape, ordered (inclusively) from put-in to take-out"
    shape: [Coordinates!]!
    "Array of tags"
    tags: [Tag!]!
    "Take-out location"
    takeOut: Point!
    "IANA timezone name, one of listed here https://github.com/evansiroky/timezone-boundary-builder"
    timezone: String
    "When was this section last modified"
    updatedAt: DateTime!
    "true if this section has been reviewed by our moderators"
    verified: Boolean
  }

  input SectionAdminSettings {
    "Whether this section is an exception in premium reegion"
    demo: Boolean!
  }

  """
  Represents information on who, how and when modified the secttion.
  For administrators only.
  """
  type SectionEditLogEntry {
    "Create, update, media_create, etc."
    action: String!
    "Date of action"
    createdAt: DateTime!
    "JSON diff of change"
    diff: JSON
    "User who edited or created the section"
    editor: User!
    "Log entry UUID"
    id: ID!
    "Section"
    section: Section!
  }

  input SectionInput {
    "Alternative names, e.g. english transliteration for names that contain umlauts"
    altNames: [String!]
    "Copyright"
    copyright: String
    "Author's user ID, defaults to current user"
    createdBy: ID
    "Guidebook page in markdown. Subject to premium access limitations"
    description: String
    "Floating point difficulty value, i.e. 4.5 means IV+"
    difficulty: Float!
    "Short extra difficulty to be written in brackets, i.e. what's inside brackets for 'IV (2xV, X)' difficuly"
    difficultyXtra: String
    "Approximate section length, in km"
    distance: Float
    "Approximate difference between put-in and take-out altitudes, in m"
    drop: Float
    "Dictionay value, approximate time required to run this section"
    duration: Int
    "Flow gauge bindings"
    flows: GaugeBindingInput
    "Human-friendly description to complement gauge bindings, e.g. 'After heavy rainfall'"
    flowsText: String
    "Section gauge"
    gauge: RefInput
    "What should be changed about this section?"
    helpNeeded: String
    "Section is not ready for publication yet"
    hidden: Boolean!
    "UUID of existing section or null to create new section"
    id: ID
    "Optional field for imported data"
    importId: String
    "Level gauge bindings"
    levels: GaugeBindingInput
    "Licensing information"
    license: LicenseInput
    "Photos, videos and blogs"
    media: [MediaInput!]!
    "Human-friendly section name (without river name, e.g. just 'Upper')"
    name: String!
    "Points of interest"
    pois: [PointInput!]!
    "Subjective rating from 0 to 5 stars, half stars allowed"
    rating: Float
    "Section region. Optional, but required when creating new river"
    region: RefInput
    "Section river"
    river: RefInput!
    "Human-friendly description to complement numeric months list"
    season: String
    "Array of numers between 0 and 23, corresponding to half-months"
    seasonNumeric: [Int!]!
    "Array of [lon, lat, alt] points to roughly match river shape, ordered (inclusively) from put-in to take-out"
    shape: [Coordinates!]!
    "Array of tags"
    tags: [RefInput!]!
    "IANA timezone name, one of listed here https://github.com/evansiroky/timezone-boundary-builder"
    timezone: String
  }

  type SectionMediaConnection {
    count: Int!
    nodes: [Media!]!
  }

  input SectionsEditLogFilter {
    editorId: ID
    regionId: ID
  }

  type SectionsEditLogList {
    count: Int!
    nodes: [SectionEditLogEntry!]!
  }

  input SectionsFilter {
    "list only the sections that current user can edit"
    editable: Boolean
    "Region ID"
    regionId: ID
    "River ID"
    riverId: ID
    "String to search in full section name (river name + section name)"
    search: String
    """
    Only list sections that weere update after this date

    @deprecated Use special query argument instead
    """
    updatedAfter: DateTime @deprecated
    "list only verified or unverified sections"
    verified: Boolean
  }

  type SectionsList {
    count: Int!
    nodes: [Section!]!
  }

  """
  This is not necessary social media account, and more like login method
  It will return "local" for email+password auth
  It's not renamed for backward compatibility reason
  """
  type SocialMediaAccount {
    id: ID!
    "local/facebook/apple"
    provider: String!
  }

  enum SortDirection {
    asc
    desc
  }

  type Source implements NamedNode & Timestamped {
    "Creation date"
    createdAt: DateTime!
    "Cron schedule"
    cron: String @admin
    "Is harvesting ON?"
    enabled: Boolean
    "Gauges"
    gauges(page: Page): SourceGaugeConnection!
    "Unique id"
    id: ID!
    "Human-friendly data source name"
    name: String!
    "Regions"
    regions(page: Page): SourceRegionConnection!
    "Arbitrary upstream request params"
    requestParams: JSON @admin
    "gorge script name"
    script: String!
    "Last harvest operation status"
    status: HarvestStatus
    "Terms of use as given by upstream data provider. Markdown."
    termsOfUse: String
    "Last modification date"
    updatedAt: DateTime!
    "Upstream provider web page (for humans)"
    url: String
  }

  type SourceGaugeConnection {
    count: Int!
    nodes: [Gauge!]!
  }

  input SourceInput {
    "Cron schedule"
    cron: String
    "Existing source UUID or null to create new surce"
    id: ID
    "Human-friendly data source name"
    name: String!
    "Regions to link with this source"
    regions: [RefInput!]!
    "Arbitrary upstream request params"
    requestParams: JSON
    "gorge script name"
    script: String!
    "Terms of use as given by upstream data provider. Markdown. "
    termsOfUse: String
    "Upstream provider web page (for humans)"
    url: String
  }

  type SourceRegionConnection {
    count: Int!
    nodes: [Region!]!
  }

  type SourcesList {
    count: Int!
    nodes: [Source!]!
  }

  type Suggestion {
    "Optional copyright for media"
    copyright: String
    "Automatical creation date"
    createdAt: DateTime!
    "Optional, some suggestions can be anonymous"
    createdBy: User
    "Text, optional, but maybe be enforced in validations"
    description: String
    "UUID"
    id: ID!
    "Absolute image url (or thumb, when parameters are set)"
    image(height: Int, width: Int): String
    "Resolution of image - [width, height] tuple"
    resolution: [Int!]
    "when was the suggestion resolved or rejected"
    resolvedAt: DateTime
    "admin/editor who accepted or rejected the suggestion"
    resolvedBy: User
    "Section to which this suggestion is related"
    section: Section!
    "status"
    status: SuggestionStatus!
  }

  "Suggestions cannot be edited, hence no id"
  input SuggestionInput {
    "Optional copyright"
    copyright: String
    "Text, optional, but maybe be enforced in validations"
    description: String
    "Name of attached image"
    filename: String
    "Image resolution. Required when attaching image"
    resolution: [Int!]
    "Section to which this suggestion is related"
    section: RefInput!
  }

  enum SuggestionStatus {
    accepted
    pending
    rejected
  }

  input SuggestionsFilter {
    status: [SuggestionStatus!]
    userId: String
  }

  type SuggestionsList {
    count: Int!
    nodes: [Suggestion!]!
  }

  type Tag {
    "Tag category enum"
    category: TagCategory!
    "unique string, can be used as slug"
    id: String!
    "Human-frindly tag label"
    name: String!
  }

  enum TagCategory {
    hazards
    kayaking
    misc
    supply
  }

  input TagInput {
    "Tag category enum"
    category: TagCategory!
    "unique string, can be used as slug"
    id: String!
    "Human-frindly tag label"
    name: String!
  }

  interface Timestamped {
    "creation date"
    createdAt: DateTime!
    "date of last modification"
    updatedAt: DateTime!
  }

  type UploadLink {
    formData: JSON!
    key: String
    postURL: String!
  }

  type User implements NamedNode & Timestamped {
    """
    Contains accounts (login methods) that this user has: local/fb/apple
    """
    accounts: [SocialMediaAccount!]!
    "user is admin"
    admin: Boolean!
    "userpic full url"
    avatar: String
    "registration date"
    createdAt: DateTime!
    "user is editor in any of regions"
    editor: Boolean!
    "Special settings for editors"
    editorSettings: EditorSettings
    "email, not public"
    email: String
    "Unique id"
    id: ID!
    "Prefer imperial measurement system"
    imperial: Boolean!
    "Prerferred UI language"
    language: String!
    "Username"
    name: String!
    "Which region groups current user has purchased"
    purchasedGroups: [Group!]!
    "Which regions current user has purchased"
    purchasedRegions: [Region!]!
    "last profle update date"
    updatedAt: DateTime!
    "True if user is verified"
    verified: Boolean!
  }

  input UserFilterOptions {
    editorsOnly: Boolean
    searchString: String
  }

  input UserInput {
    "userpic filename"
    avatar: String
    "email, not public"
    email: String
    "Prefer imperial measurement system"
    imperial: Boolean
    "Prerferred UI language"
    language: String
    "username"
    name: String
  }
`;
