/* eslint-disable @typescript-eslint/no-explicit-any */
import * as Types from '@whitewater-guide/schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type FindRiversQueryVariables = Types.Exact<{
  filter?: Types.InputMaybe<Types.RiversFilter>;
  page?: Types.InputMaybe<Types.Page>;
}>;

export type FindRiversQuery = {
  __typename?: 'Query';
  rivers: {
    __typename?: 'RiversList';
    nodes: Array<{ __typename?: 'River'; id: string; name: string }>;
  };
};

export const FindRiversDocument = gql`
  query findRivers($filter: RiversFilter, $page: Page) {
    rivers(filter: $filter, page: $page) {
      nodes {
        id
        name
      }
    }
  }
`;

/**
 * __useFindRiversQuery__
 *
 * To run a query within a React component, call `useFindRiversQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindRiversQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindRiversQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useFindRiversQuery(
  baseOptions?: Apollo.QueryHookOptions<
    FindRiversQuery,
    FindRiversQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FindRiversQuery, FindRiversQueryVariables>(
    FindRiversDocument,
    options,
  );
}
export function useFindRiversLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FindRiversQuery,
    FindRiversQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FindRiversQuery, FindRiversQueryVariables>(
    FindRiversDocument,
    options,
  );
}
export type FindRiversQueryHookResult = ReturnType<typeof useFindRiversQuery>;
export type FindRiversLazyQueryHookResult = ReturnType<
  typeof useFindRiversLazyQuery
>;
export type FindRiversQueryResult = Apollo.QueryResult<
  FindRiversQuery,
  FindRiversQueryVariables
>;
