/* eslint-disable @typescript-eslint/no-explicit-any */
import * as Types from '@whitewater-guide/schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ChangeRiverRegionMutationVariables = Types.Exact<{
  riverId: Types.Scalars['ID'];
  regionId: Types.Scalars['ID'];
}>;

export type ChangeRiverRegionMutation = {
  __typename?: 'Mutation';
  changeRiverRegion?:
    | {
        __typename?: 'River';
        id: string;
        region: { __typename?: 'Region'; id: string };
      }
    | null
    | undefined;
};

export const ChangeRiverRegionDocument = gql`
  mutation changeRiverRegion($riverId: ID!, $regionId: ID!) {
    changeRiverRegion(riverId: $riverId, regionId: $regionId) {
      id
      region {
        id
      }
    }
  }
`;
export type ChangeRiverRegionMutationFn = Apollo.MutationFunction<
  ChangeRiverRegionMutation,
  ChangeRiverRegionMutationVariables
>;

/**
 * __useChangeRiverRegionMutation__
 *
 * To run a mutation, you first call `useChangeRiverRegionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeRiverRegionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeRiverRegionMutation, { data, loading, error }] = useChangeRiverRegionMutation({
 *   variables: {
 *      riverId: // value for 'riverId'
 *      regionId: // value for 'regionId'
 *   },
 * });
 */
export function useChangeRiverRegionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ChangeRiverRegionMutation,
    ChangeRiverRegionMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ChangeRiverRegionMutation,
    ChangeRiverRegionMutationVariables
  >(ChangeRiverRegionDocument, options);
}
export type ChangeRiverRegionMutationHookResult = ReturnType<
  typeof useChangeRiverRegionMutation
>;
export type ChangeRiverRegionMutationResult =
  Apollo.MutationResult<ChangeRiverRegionMutation>;
export type ChangeRiverRegionMutationOptions = Apollo.BaseMutationOptions<
  ChangeRiverRegionMutation,
  ChangeRiverRegionMutationVariables
>;
