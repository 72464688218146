/* eslint-disable @typescript-eslint/no-explicit-any */
import * as Types from '@whitewater-guide/schema';

import { gql } from '@apollo/client';
import { MyProfileFragmentDoc } from '@whitewater-guide/schema';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type MyProfileQueryVariables = Types.Exact<{ [key: string]: never }>;

export type MyProfileQuery = {
  __typename?: 'Query';
  me?:
    | {
        __typename?: 'User';
        editor: boolean;
        admin: boolean;
        language: string;
        imperial: boolean;
        verified: boolean;
        id: string;
        name: string;
        avatar?: string | null | undefined;
        email?: string | null | undefined;
        editorSettings?:
          | {
              __typename?: 'EditorSettings';
              language?: string | null | undefined;
            }
          | null
          | undefined;
        accounts: Array<{
          __typename?: 'SocialMediaAccount';
          id: string;
          provider: string;
        }>;
      }
    | null
    | undefined;
};

export const MyProfileDocument = gql`
  query myProfile {
    me {
      ...MyProfile
    }
  }
  ${MyProfileFragmentDoc}
`;

/**
 * __useMyProfileQuery__
 *
 * To run a query within a React component, call `useMyProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyProfileQuery({
 *   variables: {
 *   },
 * });
 */
export function useMyProfileQuery(
  baseOptions?: Apollo.QueryHookOptions<
    MyProfileQuery,
    MyProfileQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MyProfileQuery, MyProfileQueryVariables>(
    MyProfileDocument,
    options,
  );
}
export function useMyProfileLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MyProfileQuery,
    MyProfileQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MyProfileQuery, MyProfileQueryVariables>(
    MyProfileDocument,
    options,
  );
}
export type MyProfileQueryHookResult = ReturnType<typeof useMyProfileQuery>;
export type MyProfileLazyQueryHookResult = ReturnType<
  typeof useMyProfileLazyQuery
>;
export type MyProfileQueryResult = Apollo.QueryResult<
  MyProfileQuery,
  MyProfileQueryVariables
>;
