/* eslint-disable @typescript-eslint/no-explicit-any */
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /**
   * When used as input, this is either [lon, lat, alt] tuple where alt can be null, or [lon, lat] tuple
   * When used as return value, this is [lon, lat, alt] tuple, where alt defaults to 0
   */
  Coordinates: CodegenCoordinates;
  Cursor: CodegenCursor;
  Date: CodegenDate;
  DateTime: CodegenDateTime;
  JSON: { [key: string]: any };
};

export type Banner = NamedNode & {
  __typename?: 'Banner';
  /** Client-side flag for lists, not persisted */
  deleted?: Maybe<Scalars['Boolean']>;
  /** Whether the banner should be displayed or not */
  enabled: Scalars['Boolean'];
  /** Arbitrary data for extra schema flexibility */
  extras?: Maybe<Scalars['JSON']>;
  /** Which region groups */
  groups?: Maybe<BannerGroupConnection>;
  /** Banner's uuid */
  id: Scalars['ID'];
  /** link for image banners, null for WebView banners */
  link?: Maybe<Scalars['String']>;
  /** For admin convenience */
  name: Scalars['String'];
  /** Banner place in the app */
  placement: BannerPlacement;
  /** Priority to choose banner, the higher the better */
  priority: Scalars['Int'];
  /** Which regions */
  regions?: Maybe<BannerRegionConnection>;
  /** Unique one-word alias */
  slug: Scalars['String'];
  /** Source to display banner from */
  source: BannerSource;
};

export type BannerGroupConnection = {
  __typename?: 'BannerGroupConnection';
  count: Scalars['Int'];
  nodes: Array<Group>;
};

export type BannerInput = {
  /** Whether the banner should be displayed or not */
  enabled: Scalars['Boolean'];
  /** Arbitrary data for extra schema flexibility */
  extras?: InputMaybe<Scalars['JSON']>;
  /** Which region groups */
  groups: Array<RefInput>;
  /** exisiting banner id or null for new banners */
  id?: InputMaybe<Scalars['ID']>;
  /** link for image banners, null for WebView banners */
  link?: InputMaybe<Scalars['String']>;
  /** For admin convenience */
  name: Scalars['String'];
  /** Banner place in the app */
  placement: BannerPlacement;
  /** Priority to choose banner, the higher the better */
  priority: Scalars['Int'];
  /** Which regions */
  regions: Array<RefInput>;
  /** Unique alias, cannot be edited */
  slug: Scalars['String'];
  /** Source to display banner from */
  source: BannerSourceInput;
};

export enum BannerKind {
  /** Static png/jpg image */
  Image = 'Image',
  /** WebView (mobile) or iFrame (web) */
  WebView = 'WebView',
}

/** Places in the app where we can display banner */
export enum BannerPlacement {
  MobileRegionDescription = 'mobileRegionDescription',
  MobileSectionDescription = 'mobileSectionDescription',
  MobileSectionMedia = 'mobileSectionMedia',
  MobileSectionRow = 'mobileSectionRow',
}

export type BannerRegionConnection = {
  __typename?: 'BannerRegionConnection';
  count: Scalars['Int'];
  nodes: Array<Region>;
};

export type BannerSource = {
  __typename?: 'BannerSource';
  /** kind of banner */
  kind: BannerKind;
  /**
   * ratio is inferred from placement
   * @deprecated Field no longer supported
   */
  ratio?: Maybe<Scalars['Float']>;
  /**
   * Deprecated, use URL istead
   * For WebView - URL
   * For Image - full url
   * @deprecated Field no longer supported
   */
  src: Scalars['String'];
  /**
   * For WebView - URL
   * For Image - full url
   */
  url: Scalars['String'];
};

export type BannerSourceSrcArgs = {
  width?: InputMaybe<Scalars['Int']>;
};

export type BannerSourceUrlArgs = {
  width?: InputMaybe<Scalars['Int']>;
};

export type BannerSourceInput = {
  kind: BannerKind;
  url: Scalars['String'];
};

export type BannersList = {
  __typename?: 'BannersList';
  count: Scalars['Int'];
  nodes: Array<Banner>;
};

export type BoomPromoInfo = {
  __typename?: 'BoomPromoInfo';
  /** Promocode pre-generated and sent to backers */
  code: Scalars['String'];
  /** regions group name which this promo code grants or null if this is one-region promo code */
  groupName?: Maybe<Scalars['String']>;
  /** Null for one-region promo codes, group sku otherwise */
  groupSku?: Maybe<Scalars['String']>;
  /** Unique id, same as code */
  id: Scalars['ID'];
  /** true if promocode has been already used */
  redeemed?: Maybe<Scalars['Boolean']>;
};

export type BulkInsertResult = {
  __typename?: 'BulkInsertResult';
  count: Scalars['Int'];
  log: Scalars['String'];
};

export type Descent = Node &
  Timestamped & {
    __typename?: 'Descent';
    /** User's comment */
    comment?: Maybe<Scalars['String']>;
    /** Creation date */
    createdAt: Scalars['DateTime'];
    /** Duration in milliseconds */
    duration?: Maybe<Scalars['Int']>;
    /** UUID of descent */
    id: Scalars['ID'];
    /** Water level at the moment of descent */
    level?: Maybe<DescentLevel>;
    /** True if this descent should be visible to everyone */
    public?: Maybe<Scalars['Boolean']>;
    /** Section on which was descended */
    section: Section;
    /** Date and time of start of descent */
    startedAt: Scalars['DateTime'];
    /** Date of last update */
    updatedAt: Scalars['DateTime'];
    /** User who made this decent */
    user: User;
  };

export type DescentEdge = {
  __typename?: 'DescentEdge';
  cursor: Scalars['Cursor'];
  node: Descent;
};

export type DescentInput = {
  /** User's comment */
  comment?: InputMaybe<Scalars['String']>;
  /** Duration in milliseconds */
  duration?: InputMaybe<Scalars['Int']>;
  /** UUID of existing descent or null for new desceent */
  id?: InputMaybe<Scalars['ID']>;
  /** Water level at the moment of descent */
  level?: InputMaybe<DescentLevelInput>;
  /** True if this descent should be visible to everyone */
  public?: InputMaybe<Scalars['Boolean']>;
  /** ID of section which was decended */
  sectionId: Scalars['ID'];
  /** Date and time of start of descent */
  startedAt: Scalars['DateTime'];
};

export type DescentLevel = {
  __typename?: 'DescentLevel';
  unit?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['Float']>;
};

export type DescentLevelInput = {
  unit?: InputMaybe<Scalars['String']>;
  value: Scalars['Float'];
};

export type DescentsConnection = {
  __typename?: 'DescentsConnection';
  edges: Array<DescentEdge>;
  pageInfo: PageInfo;
};

export type DescentsFilter = {
  /** Range of difficulty of descended section */
  difficulty?: InputMaybe<Array<Scalars['Float']>>;
  /** Max start date of descent */
  endDate?: InputMaybe<Scalars['DateTime']>;
  /** ID of descended section */
  sectionId?: InputMaybe<Scalars['String']>;
  /** Search string for descended section name */
  sectionName?: InputMaybe<Scalars['String']>;
  /** Min start date of descent */
  startDate?: InputMaybe<Scalars['DateTime']>;
  /** ID of user who recorded this descent */
  userId?: InputMaybe<Scalars['String']>;
};

export type EditorSettings = {
  __typename?: 'EditorSettings';
  /** current editor UI language */
  language?: Maybe<Scalars['String']>;
};

export type EditorSettingsInput = {
  language?: InputMaybe<Scalars['String']>;
};

export type Gauge = NamedNode &
  Timestamped & {
    __typename?: 'Gauge';
    /** Unique code from upsteam, or synthetic code if not provided */
    code: Scalars['String'];
    /** Creation date (in whitewater.guide) */
    createdAt: Scalars['DateTime'];
    /** Is gauge being harvested? */
    enabled?: Maybe<Scalars['Boolean']>;
    /** Flow unit (e.g. m3/s, cfs), or null if gauge doesn't measure flows */
    flowUnit?: Maybe<Scalars['String']>;
    /** Unique ID */
    id: Scalars['ID'];
    /**
     * Deprecated, renamed to latestMeasurement
     * @deprecated Field no longer supported
     */
    lastMeasurement?: Maybe<Measurement>;
    /** Last known measurement */
    latestMeasurement?: Maybe<Measurement>;
    /** Level unit (e.g. m, inches), or null if gauge doesn't measure levels */
    levelUnit?: Maybe<Scalars['String']>;
    /** Gauge location */
    location?: Maybe<Point>;
    /** Human-friendly name */
    name: Scalars['String'];
    /** Arbitrary upstream request params */
    requestParams?: Maybe<Scalars['JSON']>;
    /** Gauge's data source */
    source: Source;
    /** Last known harvest result */
    status?: Maybe<HarvestStatus>;
    /** IANA timezone name, one of listed here https://github.com/evansiroky/timezone-boundary-builder */
    timezone?: Maybe<Scalars['String']>;
    /** Updated date (in whitewater.guide) */
    updatedAt: Scalars['DateTime'];
    /** Gauge web page (for humans) */
    url?: Maybe<Scalars['String']>;
  };

export type GaugeBinding = {
  __typename?: 'GaugeBinding';
  /** If true, then the gauge gives very vague idea of actual section flows (e.g. the gauge is on other tributary) */
  approximate?: Maybe<Scalars['Boolean']>;
  /** Optional formula to compute section flow from gauge value, e.g '(x + 10) * 2' where x is gauge value */
  formula?: Maybe<Scalars['String']>;
  /** It is insanely dangerous or physically impossible to run river this high */
  impossible?: Maybe<Scalars['Float']>;
  /** The river is still runnable above this level, but difficulty is considerably higher than mentioned here */
  maximum?: Maybe<Scalars['Float']>;
  /** Below this level the river is not enjoyable to paddle */
  minimum?: Maybe<Scalars['Float']>;
  /** The best level for paddlers who consider themselves to be in the same class as the section */
  optimum?: Maybe<Scalars['Float']>;
};

export type GaugeBindingInput = {
  /** If true, then the gauge gives very vague idea of actual section flows (e.g. the gauge is on other tributary) */
  approximate?: InputMaybe<Scalars['Boolean']>;
  /** Optional formula to compute section flow from gauge value, e.g '(x + 10) * 2' where x is gauge value */
  formula?: InputMaybe<Scalars['String']>;
  /** It is insanely dangerous or physically impossible to run river this high */
  impossible?: InputMaybe<Scalars['Float']>;
  /** The river is still runnable above this level, but difficulty is considerably higher than mentioned here */
  maximum?: InputMaybe<Scalars['Float']>;
  /** Below this level the river is not enjoyable to paddle */
  minimum?: InputMaybe<Scalars['Float']>;
  /** The best level for paddlers who consider themselves to be in the same class as the section */
  optimum?: InputMaybe<Scalars['Float']>;
};

export type GaugeInput = {
  /** Unique code from upsteam, or synthetic code if not provided */
  code: Scalars['String'];
  /** Flow unit (e.g. m3/s, cfs), or null if gauge doesn't measure flows */
  flowUnit?: InputMaybe<Scalars['String']>;
  /** UUID of exisitng gauge or null to create new gauge */
  id?: InputMaybe<Scalars['ID']>;
  /** Level unit (e.g. m, inches), or null if gauge doesn't measure levels */
  levelUnit?: InputMaybe<Scalars['String']>;
  /** Gauge location */
  location?: InputMaybe<PointInput>;
  /** Gauge name */
  name: Scalars['String'];
  /** Arbitrary upstream request params */
  requestParams?: InputMaybe<Scalars['JSON']>;
  /** Gauge's source */
  source: RefInput;
  /** IANA timezone name, one of listed here https://github.com/evansiroky/timezone-boundary-builder */
  timezone?: InputMaybe<Scalars['String']>;
  /** Gauge web page (for humans) */
  url?: InputMaybe<Scalars['String']>;
};

export type GaugesFilter = {
  /** Gauge's region ID */
  regionId?: InputMaybe<Scalars['ID']>;
  /** Search string for gauge name */
  search?: InputMaybe<Scalars['String']>;
  /** Gauge's source ID */
  sourceId?: InputMaybe<Scalars['ID']>;
};

export type GaugesList = {
  __typename?: 'GaugesList';
  count: Scalars['Int'];
  nodes: Array<Gauge>;
};

/** Group of regions, used to provide access to several premium regions with one promocode */
export type Group = NamedNode & {
  __typename?: 'Group';
  /** unique string, can be used as slug */
  id: Scalars['ID'];
  /** Human-friendly group name */
  name: Scalars['String'];
  /** Regions */
  regions: GroupRegionConnection;
  /** SKU - identifier in iOS/Android store */
  sku?: Maybe<Scalars['String']>;
};

export type GroupInput = {
  /** unique string, can be used as slug */
  id?: InputMaybe<Scalars['String']>;
  /** Human-friendly group name */
  name: Scalars['String'];
  /** SKU - identifier in iOS/Android store */
  sku?: InputMaybe<Scalars['String']>;
};

export type GroupRegionConnection = {
  __typename?: 'GroupRegionConnection';
  count: Scalars['Int'];
  nodes: Array<Region>;
};

export type GroupsList = {
  __typename?: 'GroupsList';
  count: Scalars['Int'];
  nodes: Array<Group>;
};

/** Meta information about gauge processing by whitewater.guide */
export type HarvestStatus = {
  __typename?: 'HarvestStatus';
  /** How many measurements were harvested last time */
  count?: Maybe<Scalars['Int']>;
  /** Error message from last harvest */
  error?: Maybe<Scalars['String']>;
  /** When was this gauge/source last harvested */
  lastRun?: Maybe<Scalars['DateTime']>;
  /** When was this gauge/source last harvested and returned one or more measurements */
  lastSuccess?: Maybe<Scalars['DateTime']>;
  /**
   * When will this gauge/source be harvested next time
   * Deprecated, renamed to nextRun
   * @deprecated Field no longer supported
   */
  next?: Maybe<Scalars['DateTime']>;
  /** When will this gauge/source be harvested next time */
  nextRun?: Maybe<Scalars['DateTime']>;
  /**
   * Did last harvest result in error or not
   * Deprecated, because now it's just error field as boolean
   * @deprecated Field no longer supported
   */
  success?: Maybe<Scalars['Boolean']>;
  /**
   * When was this gauge/source last harvested
   * Depreatced, renamed to lastRun
   * @deprecated Field no longer supported
   */
  timestamp?: Maybe<Scalars['DateTime']>;
};

/** This type represents information about licensing of piece of content (region/section/media) */
export type License = {
  __typename?: 'License';
  /**
   * Full license name
   * e.g. 'Attribution-ShareAlike 4.0 International (CC BY-SA 4.0)'
   */
  name: Scalars['String'];
  /**
   * License code which can be used to search it and to get standard logo
   * e.g. 'CC_BY-SA'
   */
  slug?: Maybe<Scalars['String']>;
  /**
   * License text url
   * e.g. https://creativecommons.org/licenses/by-sa/4.0/
   */
  url?: Maybe<Scalars['String']>;
};

/** This type represents information about licensing of piece of content (region/section/media) */
export type LicenseInput = {
  /**
   * Full license name
   * e.g. 'Attribution-ShareAlike 4.0 International (CC BY-SA 4.0)'
   */
  name: Scalars['String'];
  /**
   * License code which can be used to search it and to get standard logo
   * e.g. 'CC_BY-SA'
   */
  slug?: InputMaybe<Scalars['String']>;
  /**
   * License text url
   * e.g. https://creativecommons.org/licenses/by-sa/4.0/
   */
  url?: InputMaybe<Scalars['String']>;
};

/** Measurement represents gauge's value at one point in time */
export type Measurement = {
  __typename?: 'Measurement';
  /** Flow value (cms/cfs etc.) if present */
  flow?: Maybe<Scalars['Float']>;
  /** Level value (cm/inches etc.) if present */
  level?: Maybe<Scalars['Float']>;
  /** When was this measurement taken (UTC) */
  timestamp: Scalars['DateTime'];
};

export type MeasurementsFilter = {
  /** Start of period in UTC, see gorge for default value */
  from?: InputMaybe<Scalars['DateTime']>;
  /** End of period in UTC, defaults to now */
  to?: InputMaybe<Scalars['DateTime']>;
};

export type Media = Timestamped & {
  __typename?: 'Media';
  /** copyright */
  copyright?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  /** Not persistent. Indicates that client should not display this item in lists */
  deleted?: Maybe<Scalars['Boolean']>;
  /** Human-friendly description */
  description?: Maybe<Scalars['String']>;
  /** Unique ID */
  id: Scalars['ID'];
  /** Absolute image url (or thumb, when parameters are set) */
  image?: Maybe<Scalars['String']>;
  /** Photo, video or blog */
  kind: MediaKind;
  /** Licensing information */
  license?: Maybe<License>;
  /** Required for images - [width, height] array. Makes no sense for blogs and videos (yet) */
  resolution?: Maybe<Array<Scalars['Int']>>;
  /** Image size in bytes. Null for blogs and videos */
  size?: Maybe<Scalars['Int']>;
  updatedAt: Scalars['DateTime'];
  /**
   * Full URL for videos and blogs,
   * For images, use image instead
   */
  url: Scalars['String'];
  /** weight for sorting */
  weight?: Maybe<Scalars['Int']>;
};

export type MediaImageArgs = {
  height?: InputMaybe<Scalars['Int']>;
  width?: InputMaybe<Scalars['Int']>;
};

export type MediaInput = {
  /** Copyright */
  copyright?: InputMaybe<Scalars['String']>;
  /** Human-friendly description */
  description?: InputMaybe<Scalars['String']>;
  /** UUID of existing media or null for new media */
  id?: InputMaybe<Scalars['ID']>;
  /** Photo, video or blog */
  kind: MediaKind;
  /** Licensing information */
  license?: InputMaybe<LicenseInput>;
  /** Required for images - [width, height] array. Makes no sense for blogs and videos (yet) */
  resolution?: InputMaybe<Array<Scalars['Int']>>;
  /** Full URL for videos and blogs, filename for uploaded images */
  url: Scalars['String'];
  /** Weight for sorting */
  weight?: InputMaybe<Scalars['Int']>;
};

export enum MediaKind {
  Blog = 'blog',
  Photo = 'photo',
  Video = 'video',
}

export type MediaList = {
  __typename?: 'MediaList';
  count: Scalars['Int'];
  nodes: Array<Media>;
};

export type Mutation = {
  __typename?: 'Mutation';
  /** Adds user to the list of region editors */
  addEditor?: Maybe<Scalars['Boolean']>;
  /** Adds FCM token for push notifications */
  addFCMToken?: Maybe<Scalars['Boolean']>;
  /**
   * deprecated, used by old mobile client.
   * @deprecated Field no longer supported
   */
  addPurchase?: Maybe<Scalars['Boolean']>;
  /** Adds region to group */
  addRegionToGroup?: Maybe<Scalars['Boolean']>;
  /** Creates suggestion */
  addSuggestion?: Maybe<Suggestion>;
  /** Changes admin-level settings of region */
  administrateRegion?: Maybe<Region>;
  /** Changes admin-only properties of a section */
  administrateSection?: Maybe<Section>;
  /** Automatically creates or updates gauges in this source */
  autofillSource?: Maybe<Array<Maybe<Gauge>>>;
  /**
   * This mutation will fetch tar.gz archive and insert sections in it
   * Each JSON file in archive must be array of Partial<SectionInput> objects
   * TODO: also upload photos from archive
   */
  bulkInsert?: Maybe<BulkInsertResult>;
  /** Changes region of a river */
  changeRiverRegion?: Maybe<River>;
  /**
   * Called by user who was registered via social networks.
   * Given token sent to email, will set email+password to user
   * Similar to resetRequest/reset flow for users who forgot their passwords
   */
  connectEmail?: Maybe<User>;
  /** Deletes descent by id */
  deleteDescent?: Maybe<Scalars['Boolean']>;
  /** Deletes users's profile */
  deleteProfile?: Maybe<Scalars['Boolean']>;
  /** Deletes profile of other user by id or email, admin only */
  deleteUser?: Maybe<Scalars['Boolean']>;
  /** Subscribes to mailchimp mailing list */
  mailSubscribe?: Maybe<Scalars['Boolean']>;
  /**
   * Merges source section into destination section.
   * Source section will be deleted, its logbooks, media and POIs will be reattached to destination section instead
   */
  mergeSections?: Maybe<Scalars['Boolean']>;
  /** deletes banner */
  removeBanner?: Maybe<Banner>;
  /** Removes user from the list of region editors */
  removeEditor?: Maybe<Scalars['Boolean']>;
  /** Removes FCM token for push notifications */
  removeFCMToken?: Maybe<Scalars['Boolean']>;
  /** Deletes gauge */
  removeGauge?: Maybe<Scalars['ID']>;
  /** Deletes all gauges in one source */
  removeGauges?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Deletes group */
  removeGroup?: Maybe<Scalars['String']>;
  /** Deletes media item */
  removeMedia?: Maybe<Media>;
  /** Deletes region */
  removeRegion?: Maybe<Scalars['ID']>;
  /** Removes region from group */
  removeRegionFromGroup?: Maybe<Scalars['Boolean']>;
  /** Deletes river (all the sections must be deleted beforehand) */
  removeRiver?: Maybe<Scalars['ID']>;
  /** Deletes section */
  removeSection?: Maybe<Scalars['ID']>;
  /** Deletes source */
  removeSource?: Maybe<Scalars['ID']>;
  /** Deletes tag */
  removeTag?: Maybe<Scalars['String']>;
  /**
   * Called by user who was registered via social networks before to obtain email+password login
   * Will send an email with token
   * Similar to resetRequest/reset flow for users who forgot their passwords
   */
  requestConnectEmail?: Maybe<Scalars['Boolean']>;
  /** Marks suggestion as resolved or rejected */
  resolveSuggestion?: Maybe<Suggestion>;
  /** Saves purchase. Section ID can be used to return premium section instantly */
  savePurchase?: Maybe<SavedPurchase>;
  /** Toggle favorite status of region, returns region with new status */
  toggleFavoriteRegion: Region;
  /** Toggle favorite status of section, returns section with new status */
  toggleFavoriteSection: Section;
  /** Enables or disables source */
  toggleSource?: Maybe<Source>;
  /** Modifies editor settings */
  updateEditorSettings?: Maybe<User>;
  /** Updates current user's profile */
  updateProfile?: Maybe<User>;
  /** creates or updates banner */
  upsertBanner?: Maybe<Banner>;
  /**
   * Creates or updates descent
   * Shared token can be used to indicated that several descents
   * belonging to different users are the same physical descent.
   */
  upsertDescent?: Maybe<Descent>;
  /** Creates or updates gauge */
  upsertGauge?: Maybe<Gauge>;
  /** Creates or updates group */
  upsertGroup?: Maybe<Group>;
  /** Creates or updates region */
  upsertRegion?: Maybe<Region>;
  /** Creates or updates river */
  upsertRiver?: Maybe<River>;
  /** Creates or updates section */
  upsertSection?: Maybe<Section>;
  /** Creates or updates media item belonging to a section */
  upsertSectionMedia?: Maybe<Media>;
  /** Creates or updates source */
  upsertSource?: Maybe<Source>;
  /** Creates or updates tag */
  upsertTag?: Maybe<Tag>;
};

export type MutationAddEditorArgs = {
  regionId: Scalars['ID'];
  userId: Scalars['ID'];
};

export type MutationAddFcmTokenArgs = {
  token: Scalars['String'];
};

export type MutationAddPurchaseArgs = {
  purchase: PurchaseInput;
};

export type MutationAddRegionToGroupArgs = {
  groupId: Scalars['ID'];
  regionId: Scalars['ID'];
};

export type MutationAddSuggestionArgs = {
  suggestion: SuggestionInput;
};

export type MutationAdministrateRegionArgs = {
  settings: RegionAdminSettings;
};

export type MutationAdministrateSectionArgs = {
  id: Scalars['ID'];
  settings: SectionAdminSettings;
};

export type MutationAutofillSourceArgs = {
  id: Scalars['ID'];
};

export type MutationBulkInsertArgs = {
  archiveURL: Scalars['String'];
  hidden?: InputMaybe<Scalars['Boolean']>;
  regionId: Scalars['ID'];
};

export type MutationChangeRiverRegionArgs = {
  regionId: Scalars['ID'];
  riverId: Scalars['ID'];
};

export type MutationConnectEmailArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
  token: Scalars['String'];
};

export type MutationDeleteDescentArgs = {
  id: Scalars['ID'];
};

export type MutationDeleteUserArgs = {
  email?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
};

export type MutationMailSubscribeArgs = {
  mail: Scalars['String'];
};

export type MutationMergeSectionsArgs = {
  destinationId: Scalars['ID'];
  sourceId: Scalars['ID'];
};

export type MutationRemoveBannerArgs = {
  id: Scalars['ID'];
};

export type MutationRemoveEditorArgs = {
  regionId: Scalars['ID'];
  userId: Scalars['ID'];
};

export type MutationRemoveFcmTokenArgs = {
  token: Scalars['String'];
};

export type MutationRemoveGaugeArgs = {
  id: Scalars['ID'];
};

export type MutationRemoveGaugesArgs = {
  sourceId: Scalars['ID'];
};

export type MutationRemoveGroupArgs = {
  id: Scalars['String'];
};

export type MutationRemoveMediaArgs = {
  id: Scalars['ID'];
};

export type MutationRemoveRegionArgs = {
  id: Scalars['ID'];
};

export type MutationRemoveRegionFromGroupArgs = {
  groupId: Scalars['ID'];
  regionId: Scalars['ID'];
};

export type MutationRemoveRiverArgs = {
  id: Scalars['ID'];
};

export type MutationRemoveSectionArgs = {
  id: Scalars['ID'];
};

export type MutationRemoveSourceArgs = {
  id: Scalars['ID'];
};

export type MutationRemoveTagArgs = {
  id: Scalars['String'];
};

export type MutationRequestConnectEmailArgs = {
  email: Scalars['String'];
};

export type MutationResolveSuggestionArgs = {
  id: Scalars['ID'];
  status: SuggestionStatus;
};

export type MutationSavePurchaseArgs = {
  purchase: PurchaseInput;
  sectionId?: InputMaybe<Scalars['ID']>;
};

export type MutationToggleFavoriteRegionArgs = {
  favorite: Scalars['Boolean'];
  id: Scalars['ID'];
};

export type MutationToggleFavoriteSectionArgs = {
  favorite: Scalars['Boolean'];
  id: Scalars['ID'];
};

export type MutationToggleSourceArgs = {
  enabled: Scalars['Boolean'];
  id: Scalars['ID'];
};

export type MutationUpdateEditorSettingsArgs = {
  editorSettings: EditorSettingsInput;
};

export type MutationUpdateProfileArgs = {
  user: UserInput;
};

export type MutationUpsertBannerArgs = {
  banner: BannerInput;
};

export type MutationUpsertDescentArgs = {
  descent: DescentInput;
  shareToken?: InputMaybe<Scalars['String']>;
};

export type MutationUpsertGaugeArgs = {
  gauge: GaugeInput;
};

export type MutationUpsertGroupArgs = {
  group: GroupInput;
};

export type MutationUpsertRegionArgs = {
  region: RegionInput;
};

export type MutationUpsertRiverArgs = {
  river: RiverInput;
};

export type MutationUpsertSectionArgs = {
  section: SectionInput;
};

export type MutationUpsertSectionMediaArgs = {
  media: MediaInput;
  sectionId: Scalars['ID'];
};

export type MutationUpsertSourceArgs = {
  source: SourceInput;
};

export type MutationUpsertTagArgs = {
  tag: TagInput;
};

export type NamedNode = {
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type Node = {
  id: Scalars['ID'];
};

export type Page = {
  /** Cursor pagination is currently used in descents */
  after?: InputMaybe<Scalars['Cursor']>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type PageInfo = {
  __typename?: 'PageInfo';
  endCursor?: Maybe<Scalars['Cursor']>;
  hasMore: Scalars['Boolean'];
};

export type Point = {
  __typename?: 'Point';
  /** [longitude, latitude, altitude] tuple */
  coordinates: Scalars['Coordinates'];
  /** Human-friendly description */
  description?: Maybe<Scalars['String']>;
  /** Unique ID */
  id: Scalars['ID'];
  /** Dictionary value, point type */
  kind: Scalars['String'];
  /** Human-friendly name */
  name?: Maybe<Scalars['String']>;
  /** This POI is premium */
  premium: Scalars['Boolean'];
};

export type PointInput = {
  /** [longitude, latitude, altitude] tuple, where altitude can be null */
  coordinates: Scalars['Coordinates'];
  /** Human-friendly description */
  description?: InputMaybe<Scalars['String']>;
  /** Unique ID */
  id?: InputMaybe<Scalars['ID']>;
  /** Dictionary value, point type */
  kind?: InputMaybe<Scalars['String']>;
  /** Human-friendly name */
  name?: InputMaybe<Scalars['String']>;
};

export enum PostPolicyVersion {
  V3 = 'V3',
}

export type PurchaseInput = {
  /** Arbitary extra data which we want to save just in case */
  extra?: InputMaybe<Scalars['JSON']>;
  /** ios, android or boomstarter */
  platform: Scalars['String'];
  /** Group sku or region sku */
  productId: Scalars['String'];
  /** Receipt from platform */
  receipt?: InputMaybe<Scalars['String']>;
  /** Date of purchase */
  transactionDate?: InputMaybe<Scalars['DateTime']>;
  /** This is promo code for boomstarter */
  transactionId: Scalars['String'];
};

export type Query = {
  __typename?: 'Query';
  /** get banner by id, returns null without id */
  banner?: Maybe<Banner>;
  /** lists existing banners */
  banners?: Maybe<BannersList>;
  /** Gets information about promocode. Is it group promocode or one-region promocode */
  checkBoomPromo?: Maybe<BoomPromoInfo>;
  /**
   * Get descent by id or share token
   * Share token allows to get private descent of other user
   * If none are provided, returns null
   */
  descent?: Maybe<Descent>;
  /** Generates share token that can be used by others to copy private descents */
  descentShareToken?: Maybe<Scalars['String']>;
  /** Lists descents */
  descents?: Maybe<DescentsConnection>;
  /** Lists favorite regions of current user */
  favoriteRegions: RegionsList;
  /** Lists favorite sections of current user, optionally filtering by region */
  favoriteSections: SectionsList;
  /** Lists users */
  findUsers: Array<User>;
  /** Gets gauge by ID. Returns null if no ID was provided */
  gauge?: Maybe<Gauge>;
  /** Lists gauges */
  gauges: GaugesList;
  /** Lists all groups, optionally only ones that include provided region */
  groups?: Maybe<GroupsList>;
  /**
   * Deprecated, renamed to simply measurements
   * @deprecated Field no longer supported
   */
  lastMeasurements: Array<Measurement>;
  /** Gets current user's profile */
  me?: Maybe<User>;
  /** Retrieves measurements by section or gauge id */
  measurements: Array<Measurement>;
  /** Gets media item by id. If no id is given, returns null */
  media?: Maybe<Media>;
  /** Gets all media by section id */
  mediaBySection: MediaList;
  /** Lists descents of current user */
  myDescents?: Maybe<DescentsConnection>;
  /** Gets regions that current user can activate with one-region promocode */
  promoRegions: Array<Region>;
  /** Gets region by ID. Returns null when ID is not provided */
  region?: Maybe<Region>;
  /** Lists region editors */
  regionEditors?: Maybe<Array<User>>;
  /** Lists regions */
  regions: RegionsList;
  /** Gets river by ID, returns null if ID was not provided */
  river?: Maybe<River>;
  /** Lists rivers */
  rivers: RiversList;
  /** Lists available gorge scripts (data sources) */
  scripts: Array<Script>;
  /** Gets section by id. Returns null when id is not provided */
  section?: Maybe<Section>;
  /** List sections */
  sections: SectionsList;
  /** Returns edit logs */
  sectionsEditLog: SectionsEditLogList;
  /** Gets source by ID. Returns null when id is not provided */
  source?: Maybe<Source>;
  /** Lists data sources */
  sources: SourcesList;
  /** Lists suggestions */
  suggestions?: Maybe<SuggestionsList>;
  /** Lists tag */
  tags: Array<Tag>;
  /** Generates link to upload image to object storage (S3/Minio) */
  uploadLink?: Maybe<UploadLink>;
};

export type QueryBannerArgs = {
  id?: InputMaybe<Scalars['ID']>;
};

export type QueryCheckBoomPromoArgs = {
  code: Scalars['String'];
};

export type QueryDescentArgs = {
  id?: InputMaybe<Scalars['ID']>;
  shareToken?: InputMaybe<Scalars['String']>;
};

export type QueryDescentShareTokenArgs = {
  id: Scalars['ID'];
};

export type QueryDescentsArgs = {
  filter?: InputMaybe<DescentsFilter>;
  page?: InputMaybe<Page>;
};

export type QueryFavoriteSectionsArgs = {
  regionId?: InputMaybe<Scalars['ID']>;
};

export type QueryFindUsersArgs = {
  filter: UserFilterOptions;
};

export type QueryGaugeArgs = {
  id?: InputMaybe<Scalars['ID']>;
};

export type QueryGaugesArgs = {
  filter?: InputMaybe<GaugesFilter>;
  page?: InputMaybe<Page>;
};

export type QueryGroupsArgs = {
  regionId?: InputMaybe<Scalars['ID']>;
};

export type QueryLastMeasurementsArgs = {
  days: Scalars['Int'];
  gaugeId?: InputMaybe<Scalars['ID']>;
  sectionId?: InputMaybe<Scalars['ID']>;
};

export type QueryMeasurementsArgs = {
  days?: InputMaybe<Scalars['Int']>;
  filter?: InputMaybe<MeasurementsFilter>;
  gaugeId?: InputMaybe<Scalars['ID']>;
  sectionId?: InputMaybe<Scalars['ID']>;
};

export type QueryMediaArgs = {
  id?: InputMaybe<Scalars['ID']>;
};

export type QueryMediaBySectionArgs = {
  page?: InputMaybe<Page>;
  sectionId: Scalars['ID'];
};

export type QueryMyDescentsArgs = {
  filter?: InputMaybe<DescentsFilter>;
  page?: InputMaybe<Page>;
};

export type QueryRegionArgs = {
  id?: InputMaybe<Scalars['ID']>;
};

export type QueryRegionEditorsArgs = {
  regionId: Scalars['ID'];
};

export type QueryRegionsArgs = {
  filter?: InputMaybe<RegionFilterOptions>;
  page?: InputMaybe<Page>;
};

export type QueryRiverArgs = {
  id?: InputMaybe<Scalars['ID']>;
};

export type QueryRiversArgs = {
  filter?: InputMaybe<RiversFilter>;
  page?: InputMaybe<Page>;
};

export type QuerySectionArgs = {
  id?: InputMaybe<Scalars['ID']>;
};

export type QuerySectionsArgs = {
  filter?: InputMaybe<SectionsFilter>;
  page?: InputMaybe<Page>;
  updatedAfter?: InputMaybe<Scalars['DateTime']>;
};

export type QuerySectionsEditLogArgs = {
  filter?: InputMaybe<SectionsEditLogFilter>;
  page?: InputMaybe<Page>;
};

export type QuerySourceArgs = {
  id?: InputMaybe<Scalars['ID']>;
};

export type QuerySourcesArgs = {
  page?: InputMaybe<Page>;
};

export type QuerySuggestionsArgs = {
  filter?: InputMaybe<SuggestionsFilter>;
  page?: InputMaybe<Page>;
};

export type QueryUploadLinkArgs = {
  version?: InputMaybe<PostPolicyVersion>;
};

export type RefInput = {
  id: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
};

export type Region = NamedNode &
  Timestamped & {
    __typename?: 'Region';
    /** Banners */
    banners: RegionBannerConnection;
    /** Approximate region bounds, array of [lon, lat, alt] tuples */
    bounds: Array<Scalars['Coordinates']>;
    /** copyright */
    copyright?: Maybe<Scalars['String']>;
    /** Cover image */
    coverImage: RegionCoverImage;
    /** Date when the region was added to whitewater.guide */
    createdAt: Scalars['DateTime'];
    /** Human-friendly markdown description */
    description?: Maybe<Scalars['String']>;
    /** Indicates that current user can edit this region and everything inside it */
    editable: Scalars['Boolean'];
    /** Indicates that current user has marked this region as favorite */
    favorite?: Maybe<Scalars['Boolean']>;
    /**
     * List of gauges.
     * Important: only gauges linked to sections are listed.
     */
    gauges: RegionGaugeConnection;
    /** Indicates that current user can see premium content in this region */
    hasPremiumAccess: Scalars['Boolean'];
    /** Indicates that only admins can see this region */
    hidden?: Maybe<Scalars['Boolean']>;
    /** Unique UUID */
    id: Scalars['ID'];
    /** Licensing information */
    license?: Maybe<License>;
    /** This is for admin to edit manually, users should get this via mediaSummary field */
    mapsSize?: Maybe<Scalars['Int']>;
    /** Summary of all media belonging to this region */
    mediaSummary: RegionMediaSummary;
    /** Human-friendly name */
    name: Scalars['String'];
    /** Points of interest */
    pois: Array<Point>;
    /** True if region has some premium content */
    premium?: Maybe<Scalars['Boolean']>;
    /** List of rivers */
    rivers: RegionRiverConnection;
    /**
     * Chat room for this region
     * This resolver will lazily create room if it doesn't exist. So it's not recommended to call it in list queries
     */
    room?: Maybe<Room>;
    /** Human-friendly description to complement numeric months list */
    season?: Maybe<Scalars['String']>;
    /** Array of numers between 0 and 23, corresponding to half-months */
    seasonNumeric: Array<Scalars['Int']>;
    /** List of sections */
    sections: RegionSectionConnection;
    /** SKU - identifier in iOS/Android store */
    sku?: Maybe<Scalars['String']>;
    /** List of sources */
    sources: RegionSourceConnection;
    /** Date when the region was last modified */
    updatedAt: Scalars['DateTime'];
  };

export type RegionBannersArgs = {
  page?: InputMaybe<Page>;
};

export type RegionGaugesArgs = {
  page?: InputMaybe<Page>;
};

export type RegionRiversArgs = {
  page?: InputMaybe<Page>;
};

export type RegionSectionsArgs = {
  filter?: InputMaybe<SectionsFilter>;
  page?: InputMaybe<Page>;
  updatedAfter?: InputMaybe<Scalars['DateTime']>;
};

export type RegionSourcesArgs = {
  page?: InputMaybe<Page>;
};

/** Region settings available only to admins */
export type RegionAdminSettings = {
  /** Cover images for various platforms */
  coverImage: RegionCoverImageInput;
  /** Indicates that only admins can see this region */
  hidden: Scalars['Boolean'];
  /** Region UUID */
  id: Scalars['ID'];
  /** Map size in bytes */
  mapsSize: Scalars['Int'];
  /** True if region has some premium content */
  premium: Scalars['Boolean'];
  /** SKU - identifier in iOS/Android store */
  sku?: InputMaybe<Scalars['String']>;
};

export type RegionBannerConnection = {
  __typename?: 'RegionBannerConnection';
  count: Scalars['Int'];
  nodes: Array<Banner>;
};

export type RegionCoverImage = {
  __typename?: 'RegionCoverImage';
  mobile?: Maybe<Scalars['String']>;
};

export type RegionCoverImageMobileArgs = {
  width?: InputMaybe<Scalars['Int']>;
};

export type RegionCoverImageInput = {
  mobile?: InputMaybe<Scalars['String']>;
};

export type RegionFilterOptions = {
  searchString?: InputMaybe<Scalars['String']>;
};

export type RegionGaugeConnection = {
  __typename?: 'RegionGaugeConnection';
  count: Scalars['Int'];
  nodes: Array<Gauge>;
};

export type RegionInput = {
  /** Approximate region bounds, array of [lon, lat, alt] tuples */
  bounds: Array<Scalars['Coordinates']>;
  /** copyright */
  copyright?: InputMaybe<Scalars['String']>;
  /** Human-friendly markdown description */
  description?: InputMaybe<Scalars['String']>;
  /** UUID of existing region or null to create new region */
  id?: InputMaybe<Scalars['ID']>;
  /** Licensing information */
  license?: InputMaybe<LicenseInput>;
  /** Human-friendly name */
  name: Scalars['String'];
  /** Points of interest */
  pois?: InputMaybe<Array<PointInput>>;
  /** Human-friendly description to complement numeric months list */
  season?: InputMaybe<Scalars['String']>;
  /** Array of numers between 0 and 23, corresponding to half-months */
  seasonNumeric?: InputMaybe<Array<Scalars['Int']>>;
};

export type RegionMediaSummary = {
  __typename?: 'RegionMediaSummary';
  blog: RegionMediaSummaryItem;
  maps: RegionMediaSummaryItem;
  photo: RegionMediaSummaryItem;
  video: RegionMediaSummaryItem;
};

export type RegionMediaSummaryItem = {
  __typename?: 'RegionMediaSummaryItem';
  /** Total number of media items of certain kind */
  count: Scalars['Int'];
  /** Total stored size of media items of certain kind */
  size: Scalars['Int'];
};

export type RegionRiverConnection = {
  __typename?: 'RegionRiverConnection';
  count: Scalars['Int'];
  nodes: Array<River>;
};

export type RegionSectionConnection = {
  __typename?: 'RegionSectionConnection';
  count: Scalars['Int'];
  nodes: Array<Section>;
};

export type RegionSourceConnection = {
  __typename?: 'RegionSourceConnection';
  count: Scalars['Int'];
  nodes: Array<Source>;
};

export type RegionsList = {
  __typename?: 'RegionsList';
  count: Scalars['Int'];
  nodes: Array<Region>;
};

export type River = NamedNode &
  Timestamped & {
    __typename?: 'River';
    /** Alternative names, e.g. english transliteration for names that contain umlauts */
    altNames: Array<Scalars['String']>;
    /** Creation date */
    createdAt: Scalars['DateTime'];
    /** Unique river ID */
    id: Scalars['ID'];
    /** Human-friendly river name */
    name: Scalars['String'];
    /** River region */
    region: Region;
    /** List of sections */
    sections: RiverSectionConnection;
    /** Last modification date */
    updatedAt: Scalars['DateTime'];
  };

export type RiverSectionsArgs = {
  page?: InputMaybe<Page>;
};

export type RiverInput = {
  /** Alternative names, e.g. english transliteration for names that contain umlauts */
  altNames?: InputMaybe<Array<Scalars['String']>>;
  /** UUID of existing river or null to create new river */
  id?: InputMaybe<Scalars['ID']>;
  /** Optional field for imported data */
  importId?: InputMaybe<Scalars['String']>;
  /** Human-friendly river name */
  name: Scalars['String'];
  /** River region */
  region: RefInput;
};

export type RiverSectionConnection = {
  __typename?: 'RiverSectionConnection';
  count: Scalars['Int'];
  nodes: Array<Section>;
};

export type RiversFilter = {
  /** Optional region ID */
  regionId?: InputMaybe<Scalars['ID']>;
  /** String to search in river names */
  search?: InputMaybe<Scalars['String']>;
};

export type RiversList = {
  __typename?: 'RiversList';
  count: Scalars['Int'];
  nodes: Array<River>;
};

/** Chat room */
export type Room = {
  __typename?: 'Room';
  /** Canonical room alias (starts with #uuid and has domain) */
  alias: Scalars['String'];
  /** Chat room id (starts with exclamation mark and has domain) */
  id: Scalars['ID'];
};

export type SavedPurchase = {
  __typename?: 'SavedPurchase';
  regions: Array<Region>;
  section?: Maybe<Section>;
};

/** Script represents script (data source) in gorge */
export type Script = {
  __typename?: 'Script';
  id: Scalars['String'];
  name: Scalars['String'];
};

export type Section = NamedNode &
  Timestamped & {
    __typename?: 'Section';
    /** Alternative names, e.g. english transliteration for names that contain umlauts */
    altNames: Array<Scalars['String']>;
    /** copyright */
    copyright?: Maybe<Scalars['String']>;
    /** When was this section added to whitewater.guide */
    createdAt: Scalars['DateTime'];
    /** Original author, can be null when user was deleted or for very old sections */
    createdBy?: Maybe<User>;
    /** Is demo section in premium region */
    demo?: Maybe<Scalars['Boolean']>;
    /**
     * Guidebook page in markdown. Subject to premium access limitations
     * Returns null if requires premium access
     * Returns empty string if no description provided
     */
    description?: Maybe<Scalars['String']>;
    /** Floating point difficulty value, i.e. 4.5 means IV+ */
    difficulty: Scalars['Float'];
    /** Short extra difficulty to be written in brackets, i.e. what's inside brackets for 'IV (2xV, X)' difficuly */
    difficultyXtra?: Maybe<Scalars['String']>;
    /** Approximate section length, in km */
    distance?: Maybe<Scalars['Float']>;
    /** Approximate difference between put-in and take-out altitudes, in m */
    drop?: Maybe<Scalars['Float']>;
    /** Dictionay value, approximate time required to run this section */
    duration?: Maybe<Scalars['Int']>;
    /** Indicates that current user has marked this section as favorite */
    favorite?: Maybe<Scalars['Boolean']>;
    /** Flow gauge bindings */
    flows?: Maybe<GaugeBinding>;
    /** Human-friendly description to complement gauge bindings, e.g. 'After heavy rainfall' */
    flowsText?: Maybe<Scalars['String']>;
    /** Section gauge */
    gauge?: Maybe<Gauge>;
    /** What should be changed about this section? */
    helpNeeded?: Maybe<Scalars['String']>;
    /** Section is not ready for publication yet */
    hidden: Scalars['Boolean'];
    /** Unique section ID */
    id: Scalars['ID'];
    /** Level gauge bindings */
    levels?: Maybe<GaugeBinding>;
    /** Licensing information */
    license?: Maybe<License>;
    /** Photos, videos and blogs */
    media: SectionMediaConnection;
    /** Human-friendly section name (without river name, e.g. just 'Upper') */
    name: Scalars['String'];
    /** Points of interest */
    pois: Array<Point>;
    /** Put-in location */
    putIn: Point;
    /** Subjective rating from 0 to 5 stars, half stars allowed */
    rating?: Maybe<Scalars['Float']>;
    /** Section region */
    region: Region;
    /** Section river */
    river: River;
    /**
     * Chat room for this region
     * This resolver will lazily create room if it doesn't exist. So it's not recommended to call it in list queries
     */
    room?: Maybe<Room>;
    /** Human-friendly description to complement numeric months list */
    season?: Maybe<Scalars['String']>;
    /** Array of numers between 0 and 23, corresponding to half-months */
    seasonNumeric: Array<Scalars['Int']>;
    /** Array of [lon, lat, alt] points to roughly match river shape, ordered (inclusively) from put-in to take-out */
    shape: Array<Scalars['Coordinates']>;
    /** Array of tags */
    tags: Array<Tag>;
    /** Take-out location */
    takeOut: Point;
    /** IANA timezone name, one of listed here https://github.com/evansiroky/timezone-boundary-builder */
    timezone?: Maybe<Scalars['String']>;
    /** When was this section last modified */
    updatedAt: Scalars['DateTime'];
    /** true if this section has been reviewed by our moderators */
    verified?: Maybe<Scalars['Boolean']>;
  };

export type SectionMediaArgs = {
  page?: InputMaybe<Page>;
};

export type SectionAdminSettings = {
  /** Whether this section is an exception in premium reegion */
  demo: Scalars['Boolean'];
};

/**
 * Represents information on who, how and when modified the secttion.
 * For administrators only.
 */
export type SectionEditLogEntry = {
  __typename?: 'SectionEditLogEntry';
  /** Create, update, media_create, etc. */
  action: Scalars['String'];
  /** Date of action */
  createdAt: Scalars['DateTime'];
  /** JSON diff of change */
  diff?: Maybe<Scalars['JSON']>;
  /** User who edited or created the section */
  editor: User;
  /** Log entry UUID */
  id: Scalars['ID'];
  /** Section */
  section: Section;
};

export type SectionInput = {
  /** Alternative names, e.g. english transliteration for names that contain umlauts */
  altNames?: InputMaybe<Array<Scalars['String']>>;
  /** Copyright */
  copyright?: InputMaybe<Scalars['String']>;
  /** Author's user ID, defaults to current user */
  createdBy?: InputMaybe<Scalars['ID']>;
  /** Guidebook page in markdown. Subject to premium access limitations */
  description?: InputMaybe<Scalars['String']>;
  /** Floating point difficulty value, i.e. 4.5 means IV+ */
  difficulty: Scalars['Float'];
  /** Short extra difficulty to be written in brackets, i.e. what's inside brackets for 'IV (2xV, X)' difficuly */
  difficultyXtra?: InputMaybe<Scalars['String']>;
  /** Approximate section length, in km */
  distance?: InputMaybe<Scalars['Float']>;
  /** Approximate difference between put-in and take-out altitudes, in m */
  drop?: InputMaybe<Scalars['Float']>;
  /** Dictionay value, approximate time required to run this section */
  duration?: InputMaybe<Scalars['Int']>;
  /** Flow gauge bindings */
  flows?: InputMaybe<GaugeBindingInput>;
  /** Human-friendly description to complement gauge bindings, e.g. 'After heavy rainfall' */
  flowsText?: InputMaybe<Scalars['String']>;
  /** Section gauge */
  gauge?: InputMaybe<RefInput>;
  /** What should be changed about this section? */
  helpNeeded?: InputMaybe<Scalars['String']>;
  /** Section is not ready for publication yet */
  hidden: Scalars['Boolean'];
  /** UUID of existing section or null to create new section */
  id?: InputMaybe<Scalars['ID']>;
  /** Optional field for imported data */
  importId?: InputMaybe<Scalars['String']>;
  /** Level gauge bindings */
  levels?: InputMaybe<GaugeBindingInput>;
  /** Licensing information */
  license?: InputMaybe<LicenseInput>;
  /** Photos, videos and blogs */
  media: Array<MediaInput>;
  /** Human-friendly section name (without river name, e.g. just 'Upper') */
  name: Scalars['String'];
  /** Points of interest */
  pois: Array<PointInput>;
  /** Subjective rating from 0 to 5 stars, half stars allowed */
  rating?: InputMaybe<Scalars['Float']>;
  /** Section region. Optional, but required when creating new river */
  region?: InputMaybe<RefInput>;
  /** Section river */
  river: RefInput;
  /** Human-friendly description to complement numeric months list */
  season?: InputMaybe<Scalars['String']>;
  /** Array of numers between 0 and 23, corresponding to half-months */
  seasonNumeric: Array<Scalars['Int']>;
  /** Array of [lon, lat, alt] points to roughly match river shape, ordered (inclusively) from put-in to take-out */
  shape: Array<Scalars['Coordinates']>;
  /** Array of tags */
  tags: Array<RefInput>;
  /** IANA timezone name, one of listed here https://github.com/evansiroky/timezone-boundary-builder */
  timezone?: InputMaybe<Scalars['String']>;
};

export type SectionMediaConnection = {
  __typename?: 'SectionMediaConnection';
  count: Scalars['Int'];
  nodes: Array<Media>;
};

export type SectionsEditLogFilter = {
  editorId?: InputMaybe<Scalars['ID']>;
  regionId?: InputMaybe<Scalars['ID']>;
};

export type SectionsEditLogList = {
  __typename?: 'SectionsEditLogList';
  count: Scalars['Int'];
  nodes: Array<SectionEditLogEntry>;
};

export type SectionsFilter = {
  /** list only the sections that current user can edit */
  editable?: InputMaybe<Scalars['Boolean']>;
  /** Region ID */
  regionId?: InputMaybe<Scalars['ID']>;
  /** River ID */
  riverId?: InputMaybe<Scalars['ID']>;
  /** String to search in full section name (river name + section name) */
  search?: InputMaybe<Scalars['String']>;
  /**
   * Only list sections that weere update after this date
   *
   * @deprecated Use special query argument instead
   */
  updatedAfter?: InputMaybe<Scalars['DateTime']>;
  /** list only verified or unverified sections */
  verified?: InputMaybe<Scalars['Boolean']>;
};

export type SectionsList = {
  __typename?: 'SectionsList';
  count: Scalars['Int'];
  nodes: Array<Section>;
};

/**
 * This is not necessary social media account, and more like login method
 * It will return "local" for email+password auth
 * It's not renamed for backward compatibility reason
 */
export type SocialMediaAccount = {
  __typename?: 'SocialMediaAccount';
  id: Scalars['ID'];
  /** local/facebook/apple */
  provider: Scalars['String'];
};

export enum SortDirection {
  Asc = 'asc',
  Desc = 'desc',
}

export type Source = NamedNode &
  Timestamped & {
    __typename?: 'Source';
    /** Creation date */
    createdAt: Scalars['DateTime'];
    /** Cron schedule */
    cron?: Maybe<Scalars['String']>;
    /** Is harvesting ON? */
    enabled?: Maybe<Scalars['Boolean']>;
    /** Gauges */
    gauges: SourceGaugeConnection;
    /** Unique id */
    id: Scalars['ID'];
    /** Human-friendly data source name */
    name: Scalars['String'];
    /** Regions */
    regions: SourceRegionConnection;
    /** Arbitrary upstream request params */
    requestParams?: Maybe<Scalars['JSON']>;
    /** gorge script name */
    script: Scalars['String'];
    /** Last harvest operation status */
    status?: Maybe<HarvestStatus>;
    /** Terms of use as given by upstream data provider. Markdown. */
    termsOfUse?: Maybe<Scalars['String']>;
    /** Last modification date */
    updatedAt: Scalars['DateTime'];
    /** Upstream provider web page (for humans) */
    url?: Maybe<Scalars['String']>;
  };

export type SourceGaugesArgs = {
  page?: InputMaybe<Page>;
};

export type SourceRegionsArgs = {
  page?: InputMaybe<Page>;
};

export type SourceGaugeConnection = {
  __typename?: 'SourceGaugeConnection';
  count: Scalars['Int'];
  nodes: Array<Gauge>;
};

export type SourceInput = {
  /** Cron schedule */
  cron?: InputMaybe<Scalars['String']>;
  /** Existing source UUID or null to create new surce */
  id?: InputMaybe<Scalars['ID']>;
  /** Human-friendly data source name */
  name: Scalars['String'];
  /** Regions to link with this source */
  regions: Array<RefInput>;
  /** Arbitrary upstream request params */
  requestParams?: InputMaybe<Scalars['JSON']>;
  /** gorge script name */
  script: Scalars['String'];
  /** Terms of use as given by upstream data provider. Markdown.  */
  termsOfUse?: InputMaybe<Scalars['String']>;
  /** Upstream provider web page (for humans) */
  url?: InputMaybe<Scalars['String']>;
};

export type SourceRegionConnection = {
  __typename?: 'SourceRegionConnection';
  count: Scalars['Int'];
  nodes: Array<Region>;
};

export type SourcesList = {
  __typename?: 'SourcesList';
  count: Scalars['Int'];
  nodes: Array<Source>;
};

export type Suggestion = {
  __typename?: 'Suggestion';
  /** Optional copyright for media */
  copyright?: Maybe<Scalars['String']>;
  /** Automatical creation date */
  createdAt: Scalars['DateTime'];
  /** Optional, some suggestions can be anonymous */
  createdBy?: Maybe<User>;
  /** Text, optional, but maybe be enforced in validations */
  description?: Maybe<Scalars['String']>;
  /** UUID */
  id: Scalars['ID'];
  /** Absolute image url (or thumb, when parameters are set) */
  image?: Maybe<Scalars['String']>;
  /** Resolution of image - [width, height] tuple */
  resolution?: Maybe<Array<Scalars['Int']>>;
  /** when was the suggestion resolved or rejected */
  resolvedAt?: Maybe<Scalars['DateTime']>;
  /** admin/editor who accepted or rejected the suggestion */
  resolvedBy?: Maybe<User>;
  /** Section to which this suggestion is related */
  section: Section;
  /** status */
  status: SuggestionStatus;
};

export type SuggestionImageArgs = {
  height?: InputMaybe<Scalars['Int']>;
  width?: InputMaybe<Scalars['Int']>;
};

/** Suggestions cannot be edited, hence no id */
export type SuggestionInput = {
  /** Optional copyright */
  copyright?: InputMaybe<Scalars['String']>;
  /** Text, optional, but maybe be enforced in validations */
  description?: InputMaybe<Scalars['String']>;
  /** Name of attached image */
  filename?: InputMaybe<Scalars['String']>;
  /** Image resolution. Required when attaching image */
  resolution?: InputMaybe<Array<Scalars['Int']>>;
  /** Section to which this suggestion is related */
  section: RefInput;
};

export enum SuggestionStatus {
  Accepted = 'accepted',
  Pending = 'pending',
  Rejected = 'rejected',
}

export type SuggestionsFilter = {
  status?: InputMaybe<Array<SuggestionStatus>>;
  userId?: InputMaybe<Scalars['String']>;
};

export type SuggestionsList = {
  __typename?: 'SuggestionsList';
  count: Scalars['Int'];
  nodes: Array<Suggestion>;
};

export type Tag = {
  __typename?: 'Tag';
  /** Tag category enum */
  category: TagCategory;
  /** unique string, can be used as slug */
  id: Scalars['String'];
  /** Human-frindly tag label */
  name: Scalars['String'];
};

export enum TagCategory {
  Hazards = 'hazards',
  Kayaking = 'kayaking',
  Misc = 'misc',
  Supply = 'supply',
}

export type TagInput = {
  /** Tag category enum */
  category: TagCategory;
  /** unique string, can be used as slug */
  id: Scalars['String'];
  /** Human-frindly tag label */
  name: Scalars['String'];
};

export type Timestamped = {
  /** creation date */
  createdAt: Scalars['DateTime'];
  /** date of last modification */
  updatedAt: Scalars['DateTime'];
};

export type UploadLink = {
  __typename?: 'UploadLink';
  formData: Scalars['JSON'];
  key?: Maybe<Scalars['String']>;
  postURL: Scalars['String'];
};

export type User = NamedNode &
  Timestamped & {
    __typename?: 'User';
    /** Contains accounts (login methods) that this user has: local/fb/apple */
    accounts: Array<SocialMediaAccount>;
    /** user is admin */
    admin: Scalars['Boolean'];
    /** userpic full url */
    avatar?: Maybe<Scalars['String']>;
    /** registration date */
    createdAt: Scalars['DateTime'];
    /** user is editor in any of regions */
    editor: Scalars['Boolean'];
    /** Special settings for editors */
    editorSettings?: Maybe<EditorSettings>;
    /** email, not public */
    email?: Maybe<Scalars['String']>;
    /** Unique id */
    id: Scalars['ID'];
    /** Prefer imperial measurement system */
    imperial: Scalars['Boolean'];
    /** Prerferred UI language */
    language: Scalars['String'];
    /** Username */
    name: Scalars['String'];
    /** Which region groups current user has purchased */
    purchasedGroups: Array<Group>;
    /** Which regions current user has purchased */
    purchasedRegions: Array<Region>;
    /** last profle update date */
    updatedAt: Scalars['DateTime'];
    /** True if user is verified */
    verified: Scalars['Boolean'];
  };

export type UserFilterOptions = {
  editorsOnly?: InputMaybe<Scalars['Boolean']>;
  searchString?: InputMaybe<Scalars['String']>;
};

export type UserInput = {
  /** userpic filename */
  avatar?: InputMaybe<Scalars['String']>;
  /** email, not public */
  email?: InputMaybe<Scalars['String']>;
  /** Prefer imperial measurement system */
  imperial?: InputMaybe<Scalars['Boolean']>;
  /** Prerferred UI language */
  language?: InputMaybe<Scalars['String']>;
  /** username */
  name?: InputMaybe<Scalars['String']>;
};
