/* eslint-disable @typescript-eslint/no-explicit-any */
import * as Types from '@whitewater-guide/schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type MergeSectionsMutationVariables = Types.Exact<{
  sourceId: Types.Scalars['ID'];
  destinationId: Types.Scalars['ID'];
}>;

export type MergeSectionsMutation = {
  __typename?: 'Mutation';
  mergeSections?: boolean | null | undefined;
};

export const MergeSectionsDocument = gql`
  mutation mergeSections($sourceId: ID!, $destinationId: ID!) {
    mergeSections(sourceId: $sourceId, destinationId: $destinationId)
  }
`;
export type MergeSectionsMutationFn = Apollo.MutationFunction<
  MergeSectionsMutation,
  MergeSectionsMutationVariables
>;

/**
 * __useMergeSectionsMutation__
 *
 * To run a mutation, you first call `useMergeSectionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMergeSectionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [mergeSectionsMutation, { data, loading, error }] = useMergeSectionsMutation({
 *   variables: {
 *      sourceId: // value for 'sourceId'
 *      destinationId: // value for 'destinationId'
 *   },
 * });
 */
export function useMergeSectionsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    MergeSectionsMutation,
    MergeSectionsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    MergeSectionsMutation,
    MergeSectionsMutationVariables
  >(MergeSectionsDocument, options);
}
export type MergeSectionsMutationHookResult = ReturnType<
  typeof useMergeSectionsMutation
>;
export type MergeSectionsMutationResult =
  Apollo.MutationResult<MergeSectionsMutation>;
export type MergeSectionsMutationOptions = Apollo.BaseMutationOptions<
  MergeSectionsMutation,
  MergeSectionsMutationVariables
>;
