/* eslint-disable @typescript-eslint/no-explicit-any */
import * as Types from '@whitewater-guide/schema';

import { gql } from '@apollo/client';
import {
  SectionCoreFragmentDoc,
  SectionEndsFragmentDoc,
  SectionTagsFragmentDoc,
  SectionFlowsFragmentDoc,
  SectionMeasurementsFragmentDoc,
  SectionPoIsFragmentDoc,
  SectionLicenseFragmentDoc,
} from '@whitewater-guide/schema';
import { RegionLicenseFragmentDoc } from '@whitewater-guide/schema';
import { TimestampedMetaFragmentDoc } from '@whitewater-guide/schema';
import { MediaCoreFragmentDoc } from '@whitewater-guide/schema';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SectionDetailsFragment = {
  __typename?: 'Section';
  description?: string | null | undefined;
  shape: Array<CodegenCoordinates>;
  hidden: boolean;
  helpNeeded?: string | null | undefined;
  demo?: boolean | null | undefined;
  verified?: boolean | null | undefined;
  favorite?: boolean | null | undefined;
  season?: string | null | undefined;
  seasonNumeric: Array<number>;
  distance?: number | null | undefined;
  drop?: number | null | undefined;
  duration?: number | null | undefined;
  difficulty: number;
  difficultyXtra?: string | null | undefined;
  rating?: number | null | undefined;
  timezone?: string | null | undefined;
  copyright?: string | null | undefined;
  createdAt: CodegenDateTime;
  updatedAt: CodegenDateTime;
  id: string;
  name: string;
  altNames: Array<string>;
  flowsText?: string | null | undefined;
  region: {
    __typename?: 'Region';
    id: string;
    copyright?: string | null | undefined;
    license?:
      | {
          __typename?: 'License';
          slug?: string | null | undefined;
          name: string;
          url?: string | null | undefined;
        }
      | null
      | undefined;
  };
  room?: { __typename?: 'Room'; id: string; alias: string } | null | undefined;
  putIn: {
    __typename?: 'Point';
    id: string;
    coordinates: CodegenCoordinates;
    kind: string;
  };
  takeOut: {
    __typename?: 'Point';
    id: string;
    coordinates: CodegenCoordinates;
    kind: string;
  };
  gauge?:
    | {
        __typename?: 'Gauge';
        id: string;
        name: string;
        code: string;
        levelUnit?: string | null | undefined;
        flowUnit?: string | null | undefined;
        url?: string | null | undefined;
        timezone?: string | null | undefined;
        source: {
          __typename?: 'Source';
          id: string;
          name: string;
          termsOfUse?: string | null | undefined;
        };
        location?:
          | {
              __typename?: 'Point';
              id: string;
              kind: string;
              coordinates: CodegenCoordinates;
            }
          | null
          | undefined;
        latestMeasurement?:
          | {
              __typename?: 'Measurement';
              timestamp: CodegenDateTime;
              flow?: number | null | undefined;
              level?: number | null | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
  pois: Array<{
    __typename?: 'Point';
    id: string;
    name?: string | null | undefined;
    description?: string | null | undefined;
    coordinates: CodegenCoordinates;
    kind: string;
  }>;
  tags: Array<{
    __typename?: 'Tag';
    id: string;
    name: string;
    category: Types.TagCategory;
  }>;
  license?:
    | {
        __typename?: 'License';
        slug?: string | null | undefined;
        name: string;
        url?: string | null | undefined;
      }
    | null
    | undefined;
  river: {
    __typename?: 'River';
    id: string;
    name: string;
    altNames: Array<string>;
  };
  levels?:
    | {
        __typename?: 'GaugeBinding';
        minimum?: number | null | undefined;
        maximum?: number | null | undefined;
        optimum?: number | null | undefined;
        impossible?: number | null | undefined;
        approximate?: boolean | null | undefined;
        formula?: string | null | undefined;
      }
    | null
    | undefined;
  flows?:
    | {
        __typename?: 'GaugeBinding';
        minimum?: number | null | undefined;
        maximum?: number | null | undefined;
        optimum?: number | null | undefined;
        impossible?: number | null | undefined;
        approximate?: boolean | null | undefined;
        formula?: string | null | undefined;
      }
    | null
    | undefined;
};

export type SectionDetailsQueryVariables = Types.Exact<{
  sectionId?: Types.InputMaybe<Types.Scalars['ID']>;
  withMedia: Types.Scalars['Boolean'];
  thumbSize?: Types.InputMaybe<Types.Scalars['Int']>;
}>;

export type SectionDetailsQuery = {
  __typename?: 'Query';
  section?:
    | {
        __typename?: 'Section';
        description?: string | null | undefined;
        shape: Array<CodegenCoordinates>;
        hidden: boolean;
        helpNeeded?: string | null | undefined;
        demo?: boolean | null | undefined;
        verified?: boolean | null | undefined;
        favorite?: boolean | null | undefined;
        season?: string | null | undefined;
        seasonNumeric: Array<number>;
        distance?: number | null | undefined;
        drop?: number | null | undefined;
        duration?: number | null | undefined;
        difficulty: number;
        difficultyXtra?: string | null | undefined;
        rating?: number | null | undefined;
        timezone?: string | null | undefined;
        copyright?: string | null | undefined;
        createdAt: CodegenDateTime;
        updatedAt: CodegenDateTime;
        id: string;
        name: string;
        altNames: Array<string>;
        flowsText?: string | null | undefined;
        media?: {
          __typename?: 'SectionMediaConnection';
          count: number;
          nodes: Array<{
            __typename?: 'Media';
            image?: string | null | undefined;
            id: string;
            kind: Types.MediaKind;
            copyright?: string | null | undefined;
            description?: string | null | undefined;
            url: string;
            resolution?: Array<number> | null | undefined;
            weight?: number | null | undefined;
            thumb?: string | null | undefined;
            license?:
              | {
                  __typename?: 'License';
                  name: string;
                  slug?: string | null | undefined;
                  url?: string | null | undefined;
                }
              | null
              | undefined;
          }>;
        };
        region: {
          __typename?: 'Region';
          id: string;
          copyright?: string | null | undefined;
          license?:
            | {
                __typename?: 'License';
                slug?: string | null | undefined;
                name: string;
                url?: string | null | undefined;
              }
            | null
            | undefined;
        };
        room?:
          | { __typename?: 'Room'; id: string; alias: string }
          | null
          | undefined;
        putIn: {
          __typename?: 'Point';
          id: string;
          coordinates: CodegenCoordinates;
          kind: string;
        };
        takeOut: {
          __typename?: 'Point';
          id: string;
          coordinates: CodegenCoordinates;
          kind: string;
        };
        gauge?:
          | {
              __typename?: 'Gauge';
              id: string;
              name: string;
              code: string;
              levelUnit?: string | null | undefined;
              flowUnit?: string | null | undefined;
              url?: string | null | undefined;
              timezone?: string | null | undefined;
              source: {
                __typename?: 'Source';
                id: string;
                name: string;
                termsOfUse?: string | null | undefined;
              };
              location?:
                | {
                    __typename?: 'Point';
                    id: string;
                    kind: string;
                    coordinates: CodegenCoordinates;
                  }
                | null
                | undefined;
              latestMeasurement?:
                | {
                    __typename?: 'Measurement';
                    timestamp: CodegenDateTime;
                    flow?: number | null | undefined;
                    level?: number | null | undefined;
                  }
                | null
                | undefined;
            }
          | null
          | undefined;
        pois: Array<{
          __typename?: 'Point';
          id: string;
          name?: string | null | undefined;
          description?: string | null | undefined;
          coordinates: CodegenCoordinates;
          kind: string;
        }>;
        tags: Array<{
          __typename?: 'Tag';
          id: string;
          name: string;
          category: Types.TagCategory;
        }>;
        license?:
          | {
              __typename?: 'License';
              slug?: string | null | undefined;
              name: string;
              url?: string | null | undefined;
            }
          | null
          | undefined;
        river: {
          __typename?: 'River';
          id: string;
          name: string;
          altNames: Array<string>;
        };
        levels?:
          | {
              __typename?: 'GaugeBinding';
              minimum?: number | null | undefined;
              maximum?: number | null | undefined;
              optimum?: number | null | undefined;
              impossible?: number | null | undefined;
              approximate?: boolean | null | undefined;
              formula?: string | null | undefined;
            }
          | null
          | undefined;
        flows?:
          | {
              __typename?: 'GaugeBinding';
              minimum?: number | null | undefined;
              maximum?: number | null | undefined;
              optimum?: number | null | undefined;
              impossible?: number | null | undefined;
              approximate?: boolean | null | undefined;
              formula?: string | null | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export const SectionDetailsFragmentDoc = gql`
  fragment SectionDetails on Section {
    ...SectionCore
    description
    shape
    region {
      id
      ...RegionLicense
    }
    room {
      id
      alias
    }
    ...SectionEnds
    ...SectionMeasurements
    ...SectionPOIs
    ...SectionTags
    ...SectionLicense
    ...TimestampedMeta
  }
  ${SectionCoreFragmentDoc}
  ${RegionLicenseFragmentDoc}
  ${SectionEndsFragmentDoc}
  ${SectionMeasurementsFragmentDoc}
  ${SectionPoIsFragmentDoc}
  ${SectionTagsFragmentDoc}
  ${SectionLicenseFragmentDoc}
  ${TimestampedMetaFragmentDoc}
`;
export const SectionDetailsDocument = gql`
  query sectionDetails($sectionId: ID, $withMedia: Boolean!, $thumbSize: Int) {
    section(id: $sectionId) {
      ...SectionDetails
      media @include(if: $withMedia) {
        nodes {
          ...MediaCore
          image
          thumb: image(width: $thumbSize, height: $thumbSize)
        }
        count
      }
    }
  }
  ${SectionDetailsFragmentDoc}
  ${MediaCoreFragmentDoc}
`;

/**
 * __useSectionDetailsQuery__
 *
 * To run a query within a React component, call `useSectionDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSectionDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSectionDetailsQuery({
 *   variables: {
 *      sectionId: // value for 'sectionId'
 *      withMedia: // value for 'withMedia'
 *      thumbSize: // value for 'thumbSize'
 *   },
 * });
 */
export function useSectionDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<
    SectionDetailsQuery,
    SectionDetailsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SectionDetailsQuery, SectionDetailsQueryVariables>(
    SectionDetailsDocument,
    options,
  );
}
export function useSectionDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SectionDetailsQuery,
    SectionDetailsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SectionDetailsQuery, SectionDetailsQueryVariables>(
    SectionDetailsDocument,
    options,
  );
}
export type SectionDetailsQueryHookResult = ReturnType<
  typeof useSectionDetailsQuery
>;
export type SectionDetailsLazyQueryHookResult = ReturnType<
  typeof useSectionDetailsLazyQuery
>;
export type SectionDetailsQueryResult = Apollo.QueryResult<
  SectionDetailsQuery,
  SectionDetailsQueryVariables
>;
