export interface PossibleTypesResultData {
  possibleTypes: {
    [key: string]: string[];
  };
}
const result: PossibleTypesResultData = {
  possibleTypes: {
    NamedNode: [
      'Banner',
      'Gauge',
      'Group',
      'Region',
      'River',
      'Section',
      'Source',
      'User',
    ],
    Node: ['Descent'],
    Timestamped: [
      'Descent',
      'Gauge',
      'Media',
      'Region',
      'River',
      'Section',
      'Source',
      'User',
    ],
  },
};
export default result;
