/* eslint-disable @typescript-eslint/no-explicit-any */
import * as Types from '@whitewater-guide/schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type MeasurementsQueryVariables = Types.Exact<{
  gaugeId?: Types.InputMaybe<Types.Scalars['ID']>;
  sectionId?: Types.InputMaybe<Types.Scalars['ID']>;
  days?: Types.InputMaybe<Types.Scalars['Int']>;
  filter?: Types.InputMaybe<Types.MeasurementsFilter>;
}>;

export type MeasurementsQuery = {
  __typename?: 'Query';
  measurements: Array<{
    __typename?: 'Measurement';
    timestamp: CodegenDateTime;
    flow?: number | null | undefined;
    level?: number | null | undefined;
  }>;
};

export const MeasurementsDocument = gql`
  query measurements(
    $gaugeId: ID
    $sectionId: ID
    $days: Int
    $filter: MeasurementsFilter
  ) {
    measurements(
      gaugeId: $gaugeId
      sectionId: $sectionId
      days: $days
      filter: $filter
    ) {
      timestamp
      flow
      level
    }
  }
`;

/**
 * __useMeasurementsQuery__
 *
 * To run a query within a React component, call `useMeasurementsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeasurementsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeasurementsQuery({
 *   variables: {
 *      gaugeId: // value for 'gaugeId'
 *      sectionId: // value for 'sectionId'
 *      days: // value for 'days'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useMeasurementsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    MeasurementsQuery,
    MeasurementsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MeasurementsQuery, MeasurementsQueryVariables>(
    MeasurementsDocument,
    options,
  );
}
export function useMeasurementsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MeasurementsQuery,
    MeasurementsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MeasurementsQuery, MeasurementsQueryVariables>(
    MeasurementsDocument,
    options,
  );
}
export type MeasurementsQueryHookResult = ReturnType<
  typeof useMeasurementsQuery
>;
export type MeasurementsLazyQueryHookResult = ReturnType<
  typeof useMeasurementsLazyQuery
>;
export type MeasurementsQueryResult = Apollo.QueryResult<
  MeasurementsQuery,
  MeasurementsQueryVariables
>;
