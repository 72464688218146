/* eslint-disable @typescript-eslint/no-explicit-any */
import * as Types from '@whitewater-guide/schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type RegionBreadcrumbQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;

export type RegionBreadcrumbQuery = {
  __typename?: 'Query';
  node?: { __typename?: 'Region'; id: string; name: string } | null | undefined;
};

export const RegionBreadcrumbDocument = gql`
  query regionBreadcrumb($id: ID!) {
    node: region(id: $id) {
      id
      name
    }
  }
`;

/**
 * __useRegionBreadcrumbQuery__
 *
 * To run a query within a React component, call `useRegionBreadcrumbQuery` and pass it any options that fit your needs.
 * When your component renders, `useRegionBreadcrumbQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRegionBreadcrumbQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRegionBreadcrumbQuery(
  baseOptions: Apollo.QueryHookOptions<
    RegionBreadcrumbQuery,
    RegionBreadcrumbQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<RegionBreadcrumbQuery, RegionBreadcrumbQueryVariables>(
    RegionBreadcrumbDocument,
    options,
  );
}
export function useRegionBreadcrumbLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RegionBreadcrumbQuery,
    RegionBreadcrumbQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    RegionBreadcrumbQuery,
    RegionBreadcrumbQueryVariables
  >(RegionBreadcrumbDocument, options);
}
export type RegionBreadcrumbQueryHookResult = ReturnType<
  typeof useRegionBreadcrumbQuery
>;
export type RegionBreadcrumbLazyQueryHookResult = ReturnType<
  typeof useRegionBreadcrumbLazyQuery
>;
export type RegionBreadcrumbQueryResult = Apollo.QueryResult<
  RegionBreadcrumbQuery,
  RegionBreadcrumbQueryVariables
>;
