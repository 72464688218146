/* eslint-disable @typescript-eslint/no-explicit-any */
import * as Types from '@whitewater-guide/schema';

import { gql } from '@apollo/client';
import { MediaCoreFragmentDoc } from '@whitewater-guide/schema';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SectionMediaQueryVariables = Types.Exact<{
  sectionId: Types.Scalars['ID'];
  thumbHeight?: Types.InputMaybe<Types.Scalars['Int']>;
}>;

export type SectionMediaQuery = {
  __typename?: 'Query';
  media: {
    __typename?: 'MediaList';
    nodes: Array<{
      __typename?: 'Media';
      deleted?: boolean | null | undefined;
      id: string;
      kind: Types.MediaKind;
      copyright?: string | null | undefined;
      description?: string | null | undefined;
      url: string;
      resolution?: Array<number> | null | undefined;
      weight?: number | null | undefined;
      thumb?: string | null | undefined;
      license?:
        | {
            __typename?: 'License';
            name: string;
            slug?: string | null | undefined;
            url?: string | null | undefined;
          }
        | null
        | undefined;
    }>;
  };
};

export const SectionMediaDocument = gql`
  query sectionMedia($sectionId: ID!, $thumbHeight: Int) {
    media: mediaBySection(sectionId: $sectionId) {
      nodes {
        ...MediaCore
        thumb: image(height: $thumbHeight)
        deleted
      }
    }
  }
  ${MediaCoreFragmentDoc}
`;

/**
 * __useSectionMediaQuery__
 *
 * To run a query within a React component, call `useSectionMediaQuery` and pass it any options that fit your needs.
 * When your component renders, `useSectionMediaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSectionMediaQuery({
 *   variables: {
 *      sectionId: // value for 'sectionId'
 *      thumbHeight: // value for 'thumbHeight'
 *   },
 * });
 */
export function useSectionMediaQuery(
  baseOptions: Apollo.QueryHookOptions<
    SectionMediaQuery,
    SectionMediaQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SectionMediaQuery, SectionMediaQueryVariables>(
    SectionMediaDocument,
    options,
  );
}
export function useSectionMediaLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SectionMediaQuery,
    SectionMediaQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SectionMediaQuery, SectionMediaQueryVariables>(
    SectionMediaDocument,
    options,
  );
}
export type SectionMediaQueryHookResult = ReturnType<
  typeof useSectionMediaQuery
>;
export type SectionMediaLazyQueryHookResult = ReturnType<
  typeof useSectionMediaLazyQuery
>;
export type SectionMediaQueryResult = Apollo.QueryResult<
  SectionMediaQuery,
  SectionMediaQueryVariables
>;
