/* eslint-disable @typescript-eslint/no-explicit-any */
import * as Types from '@whitewater-guide/schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GaugeBreadcrumbQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;

export type GaugeBreadcrumbQuery = {
  __typename?: 'Query';
  node?: { __typename?: 'Gauge'; id: string; name: string } | null | undefined;
};

export const GaugeBreadcrumbDocument = gql`
  query gaugeBreadcrumb($id: ID!) {
    node: gauge(id: $id) {
      id
      name
    }
  }
`;

/**
 * __useGaugeBreadcrumbQuery__
 *
 * To run a query within a React component, call `useGaugeBreadcrumbQuery` and pass it any options that fit your needs.
 * When your component renders, `useGaugeBreadcrumbQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGaugeBreadcrumbQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGaugeBreadcrumbQuery(
  baseOptions: Apollo.QueryHookOptions<
    GaugeBreadcrumbQuery,
    GaugeBreadcrumbQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GaugeBreadcrumbQuery, GaugeBreadcrumbQueryVariables>(
    GaugeBreadcrumbDocument,
    options,
  );
}
export function useGaugeBreadcrumbLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GaugeBreadcrumbQuery,
    GaugeBreadcrumbQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GaugeBreadcrumbQuery,
    GaugeBreadcrumbQueryVariables
  >(GaugeBreadcrumbDocument, options);
}
export type GaugeBreadcrumbQueryHookResult = ReturnType<
  typeof useGaugeBreadcrumbQuery
>;
export type GaugeBreadcrumbLazyQueryHookResult = ReturnType<
  typeof useGaugeBreadcrumbLazyQuery
>;
export type GaugeBreadcrumbQueryResult = Apollo.QueryResult<
  GaugeBreadcrumbQuery,
  GaugeBreadcrumbQueryVariables
>;
