/* eslint-disable @typescript-eslint/no-explicit-any */
import * as Types from '@whitewater-guide/schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SourceBreadcrumbQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;

export type SourceBreadcrumbQuery = {
  __typename?: 'Query';
  node?: { __typename?: 'Source'; id: string; name: string } | null | undefined;
};

export const SourceBreadcrumbDocument = gql`
  query sourceBreadcrumb($id: ID!) {
    node: source(id: $id) {
      id
      name
    }
  }
`;

/**
 * __useSourceBreadcrumbQuery__
 *
 * To run a query within a React component, call `useSourceBreadcrumbQuery` and pass it any options that fit your needs.
 * When your component renders, `useSourceBreadcrumbQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSourceBreadcrumbQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSourceBreadcrumbQuery(
  baseOptions: Apollo.QueryHookOptions<
    SourceBreadcrumbQuery,
    SourceBreadcrumbQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SourceBreadcrumbQuery, SourceBreadcrumbQueryVariables>(
    SourceBreadcrumbDocument,
    options,
  );
}
export function useSourceBreadcrumbLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SourceBreadcrumbQuery,
    SourceBreadcrumbQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SourceBreadcrumbQuery,
    SourceBreadcrumbQueryVariables
  >(SourceBreadcrumbDocument, options);
}
export type SourceBreadcrumbQueryHookResult = ReturnType<
  typeof useSourceBreadcrumbQuery
>;
export type SourceBreadcrumbLazyQueryHookResult = ReturnType<
  typeof useSourceBreadcrumbLazyQuery
>;
export type SourceBreadcrumbQueryResult = Apollo.QueryResult<
  SourceBreadcrumbQuery,
  SourceBreadcrumbQueryVariables
>;
