/* eslint-disable @typescript-eslint/no-explicit-any */
import * as Types from '@whitewater-guide/schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type BannerBreadcrumbQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;

export type BannerBreadcrumbQuery = {
  __typename?: 'Query';
  node?: { __typename?: 'Banner'; id: string; name: string } | null | undefined;
};

export const BannerBreadcrumbDocument = gql`
  query bannerBreadcrumb($id: ID!) {
    node: banner(id: $id) {
      id
      name
    }
  }
`;

/**
 * __useBannerBreadcrumbQuery__
 *
 * To run a query within a React component, call `useBannerBreadcrumbQuery` and pass it any options that fit your needs.
 * When your component renders, `useBannerBreadcrumbQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBannerBreadcrumbQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useBannerBreadcrumbQuery(
  baseOptions: Apollo.QueryHookOptions<
    BannerBreadcrumbQuery,
    BannerBreadcrumbQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BannerBreadcrumbQuery, BannerBreadcrumbQueryVariables>(
    BannerBreadcrumbDocument,
    options,
  );
}
export function useBannerBreadcrumbLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    BannerBreadcrumbQuery,
    BannerBreadcrumbQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    BannerBreadcrumbQuery,
    BannerBreadcrumbQueryVariables
  >(BannerBreadcrumbDocument, options);
}
export type BannerBreadcrumbQueryHookResult = ReturnType<
  typeof useBannerBreadcrumbQuery
>;
export type BannerBreadcrumbLazyQueryHookResult = ReturnType<
  typeof useBannerBreadcrumbLazyQuery
>;
export type BannerBreadcrumbQueryResult = Apollo.QueryResult<
  BannerBreadcrumbQuery,
  BannerBreadcrumbQueryVariables
>;
