/* eslint-disable @typescript-eslint/no-explicit-any */
import * as Types from '@whitewater-guide/schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type FindRegionsQueryVariables = Types.Exact<{
  filter?: Types.InputMaybe<Types.RegionFilterOptions>;
  page?: Types.InputMaybe<Types.Page>;
}>;

export type FindRegionsQuery = {
  __typename?: 'Query';
  regions: {
    __typename?: 'RegionsList';
    nodes: Array<{ __typename?: 'Region'; id: string; name: string }>;
  };
};

export const FindRegionsDocument = gql`
  query findRegions($filter: RegionFilterOptions, $page: Page) {
    regions(filter: $filter, page: $page) {
      nodes {
        id
        name
      }
    }
  }
`;

/**
 * __useFindRegionsQuery__
 *
 * To run a query within a React component, call `useFindRegionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindRegionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindRegionsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useFindRegionsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    FindRegionsQuery,
    FindRegionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FindRegionsQuery, FindRegionsQueryVariables>(
    FindRegionsDocument,
    options,
  );
}
export function useFindRegionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FindRegionsQuery,
    FindRegionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FindRegionsQuery, FindRegionsQueryVariables>(
    FindRegionsDocument,
    options,
  );
}
export type FindRegionsQueryHookResult = ReturnType<typeof useFindRegionsQuery>;
export type FindRegionsLazyQueryHookResult = ReturnType<
  typeof useFindRegionsLazyQuery
>;
export type FindRegionsQueryResult = Apollo.QueryResult<
  FindRegionsQuery,
  FindRegionsQueryVariables
>;
