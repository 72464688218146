/* eslint-disable @typescript-eslint/no-explicit-any */
import * as Types from '@whitewater-guide/schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type RemoveSectionMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;

export type RemoveSectionMutation = {
  __typename?: 'Mutation';
  removeSection?: string | null | undefined;
};

export const RemoveSectionDocument = gql`
  mutation removeSection($id: ID!) {
    removeSection(id: $id)
  }
`;
export type RemoveSectionMutationFn = Apollo.MutationFunction<
  RemoveSectionMutation,
  RemoveSectionMutationVariables
>;

/**
 * __useRemoveSectionMutation__
 *
 * To run a mutation, you first call `useRemoveSectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveSectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeSectionMutation, { data, loading, error }] = useRemoveSectionMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveSectionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveSectionMutation,
    RemoveSectionMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RemoveSectionMutation,
    RemoveSectionMutationVariables
  >(RemoveSectionDocument, options);
}
export type RemoveSectionMutationHookResult = ReturnType<
  typeof useRemoveSectionMutation
>;
export type RemoveSectionMutationResult =
  Apollo.MutationResult<RemoveSectionMutation>;
export type RemoveSectionMutationOptions = Apollo.BaseMutationOptions<
  RemoveSectionMutation,
  RemoveSectionMutationVariables
>;
